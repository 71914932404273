import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Utilities
import { generateKey } from '../../../utilities/Keys';
import { defaultSummaryHeight, defaultSummaryTitleStyles } from '../../../utilities/Defaults';

// Styles
import './CollectionAdd.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import CheckOnIcon from '../../../../common/svg/CheckOnIcon';
import CheckOffIcon from '../../../../common/svg/CheckOffIcon';

// Components
import Modal from '../../../components/modal/Modal';

// Managers
import FieldManager from '../../../../common/managers/FieldManager';
import ModelManager from '../../../../common/managers/ModelManager';
import PromptManager from '../../../../common/managers/PromptManager';

const fieldManager = new FieldManager();
const modelManager = new ModelManager();
const promptManager = new PromptManager();

/**
 * CollectionAdd Component
 * 
 * This component allows the user to add a collection.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CollectionAdd = () => {
    const { theme } = useTheme();
    const {
        collectionAddOpen,
        currentUser,
        hideProgress,
        models,
        resetVisibility,
        selectedApp,
        setCollectionAddOpen,
        setFormMode,
        setSelectedModel,
        setStatusMessage,
        showProgress
    } = useContext(Global);

    // State Variables
    const [title, setTitle] = useState('');
    const [autogen, setAutogen] = useState(true);

    // References
    const titleInputRef = useRef(null);

    // Focus the title field on collection load
    useEffect(() => {
        if (collectionAddOpen) {
            titleInputRef.current.focus();
        }
    }, [collectionAddOpen]);

    const handleAdd = async () => {

        if (!title) {
            alert('Please enter a menu item title.');
            return;
        }
    
        showProgress("Adding collection...");
    
        setCollectionAddOpen(false);
    
        const userKey = currentUser.key;
        const username = currentUser.username;
        const appKey = selectedApp.key;
    
        const newModelKey = generateKey();  // Generate key for the new model
    
        // Current timestamp
        const now = Timestamp.now();
    
        // Initialize the highest sort number to 0
        let highestSortNumber = 0;
    
        // Iterate through each model to find the highest sort number
        models.forEach(model => {
            const sortNumber = model.sort;
            if (sortNumber > highestSortNumber) {
                highestSortNumber = sortNumber;
            }
        });
    
        const titleFieldKey = generateKey();
    
        const modelData = {
            key: newModelKey,
            appKey: appKey,
            description: "",
            userKey: userKey,
            username: username,
            title: title,
            summaryHeight: defaultSummaryHeight,
            noview: [],
            noadd: [],
            noedit: [],
            nodelete: [],
            sort: highestSortNumber + 1,
            titleFieldKey: titleFieldKey, // Overridden below if autogen is enabled
            styles: defaultSummaryTitleStyles,
            dateCreated: now,
            dateModified: now,
        };
    
        // Add the model to the database first
        await modelManager.add(appKey, newModelKey, modelData);
    
        // Generate default fields if autogen is enabled
        if (autogen) {
            // Submit the model prompt
            await submitModelPrompt(selectedApp, modelData, []);
        } else {
            // We need to create a default title field if autogen is disabled
            const fieldData = {
                appKey: appKey,
                key: titleFieldKey,
                title: "Title",
                description: "Title of the item.",
                type: "text",
                sort: 0,
                modelKey: newModelKey,
                modelTitle: title,
                modelDescription: "",
                options: [],
            };
    
            // Add the title field to the database
            await fieldManager.add(appKey, titleFieldKey, newModelKey, fieldData);
    
            // Set the selected model
            setSelectedModel(modelData);
        }
    
        // Reset the title field
        setTitle('');
    
        // Reset the autogen field if necessary
        setAutogen(true);
    
        // Hide the progress panel
        hideProgress();
    };    

    const submitModelPrompt = async (app, model, models) => {
        try {

            const prompt = await promptManager.prepareModelPrompt(app.title, app.description, model.title, model.description, models);

            console.log(prompt);

            const response = await promptManager.send(prompt);

            await promptManager.processModelResponse(
                response,
                app,
                model,
                showProgress,
                setSelectedModel,
                setFormMode,
                resetVisibility,
                []);

        } catch (error) {
            console.error('Failed to fetch API response:', error);
        }
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="New Collection"
                isOpen={collectionAddOpen}
                onClose={() => setCollectionAddOpen(false)}
                width="330px">

                <div className="collection-add-wrapper">
                    <div className="collection-add-container">

                        {/* COLLECTION NAME */}
                        <div className="collection-add-label"
                            style={{ color: theme.foregroundColor }}>
                            Collection Name
                        </div>
                        <input
                            type="text"
                            name="title"
                            className="modal-input"
                            style={{
                                color: theme.foregroundColor,
                                backgroundColor: theme.backgroundColorFaded,
                                borderColor: theme.backgroundColorFaded,
                                fontFamily: selectedApp.fontFamily
                            }}
                            value={title}
                            maxLength="18"
                            onChange={e => setTitle(e.target.value)}
                            ref={titleInputRef}
                        />

                        {/* GENERATE */}
                        <div className="collection-add-autogen-wrapper">
                            <div
                                className="collection-add-checkbox"
                                onClick={() => setAutogen(!autogen)}>
                                {autogen ? (
                                    <CheckOnIcon
                                        color={theme.highlightBackgroundColor}
                                        width="18"
                                        height="18"
                                        checkColor={theme.highlightForegroundColor}
                                    />
                                ) : (
                                    <CheckOffIcon
                                        color={theme.foregroundColorFaded}
                                        width="18"
                                        height="18"
                                    />
                                )}
                            </div>
                            <div
                                className="collection-add-autogen-label"
                                onClick={() => setAutogen(!autogen)}
                                style={{ color: theme.foregroundColor }}>
                                Auto-Generate Fields
                            </div>
                        </div>

                        <div className="collection-add-buttons">
                            <button
                                type="button"
                                onClick={handleAdd}
                                className="collection-add-button"
                                style={{
                                    color: theme.foregroundColor,
                                    fontFamily: selectedApp.fontFamily
                                }}>
                                Continue...
                            </button>
                        </div>
                    </div>
                </div>

            </Modal>
        </>
    );
};

export default CollectionAdd;
