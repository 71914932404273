// Firebase
import { collection, onSnapshot, deleteDoc, doc, getDocs, query, orderBy, setDoc, Timestamp, where, writeBatch } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Utilities
import { generateKey } from '../utilities/Keys';

// Activity
import { activity } from './ActivityManager';

class HeadlineManager {

    /**
     * Method to add a new post headline.
     * 
     * @param {string} appKey - App key.
     * @param {string} user - User object.
     * @param {string} text - Text the user is posting.
     * @param {string} photoUrls - Array of uploaded image urls.
     * @param {string} documentUrls - Array of uploaded document urls.
     * @returns {headline} - New headline record.
    */
    async addPostHeadline(appKey, user, text, photoUrls = [], videoUrls = [], documents = []) {

        const now = Timestamp.now();

        const key = generateKey();

        const headline = {
            key: key,
            appKey: appKey,
            type: "POST",
            userKey: user.key,
            username: user.username,
            text: text,
            photoUrls: photoUrls,
            videoUrls: videoUrls,
            documents: documents,
            dateCreated: now
        };

        await setDoc(doc(db, collections.headlines, key), headline);

        activity.log(key, 'writes', 1);

        return headline;
    }

    /**
     * Method to add a new headline when a user joins.
     * 
     * @param {string} appKey - App key.
     * @param {string} user - User object.
     * @returns {headline} - New headline record.
    */
    async addJoinedHeadline(appKey, user) {

        const now = Timestamp.now();

        const key = generateKey();

        const headline = {
            key: key,
            type: "JOINED",
            appKey: appKey,
            userKey: user.key,
            username: user.username,
            dateCreated: now
        };

        await setDoc(doc(db, collections.headlines, key), headline);

        activity.log(key, 'writes', 1);

        return headline;
    }

    /**
     * Method to add a new headline when a record is deleted.
     * 
     * @param {string} appKey - App key.
     * @param {string} user - User object.
     * @param {string} objectTitle - Title of the object that was deleted.
     * @param {string} modelKey - Key of the model type of the object.
     * @param {string} modelTitle - Title of the model type of the object.
     * @returns {headline} - New headline record.
    */
    async addDeletedHeadline(appKey, user, objectTitle, modelKey, modelTitle) {

        if (objectTitle === undefined || objectTitle === "") return;

        const now = Timestamp.now();

        const key = generateKey();

        const headline = {
            key: key,
            type: "DELETED",
            appKey: appKey,
            userKey: user.key,
            username: user.username,
            objectTitle: objectTitle,
            modelKey: modelKey,
            modelTitle: modelTitle,
            dateCreated: now
        };

        await setDoc(doc(db, collections.headlines, key), headline);

        activity.log(key, 'writes', 1);

        return headline;
    }

    /**
     * Method to add a new headline when a comment is made on an object.
     * 
     * @param {string} appKey - App key.
     * @param {string} user - User object.
     * @param {string} modelKey - Key of the object's model type.
     * @param {string} objectKey - Key of the object.
     * @param {string} objectTitle - Title of the object.
     * @returns {headline} - New headline record.
    */
    async addCommentedHeadline(appKey, user, modelKey, objectKey, objectTitle) {

        const now = Timestamp.now();

        const key = generateKey();

        const headline = {
            key: key,
            type: "COMMENTED",
            appKey: appKey,
            userKey: user.key,
            username: user.username,
            modelKey: modelKey,
            objectKey: objectKey,
            objectTitle: objectTitle,
            dateCreated: now
        };

        await setDoc(doc(db, collections.headlines, key), headline);

        activity.log(key, 'writes', 1);

        return headline;
    }

    /**
     * Method to add a new headline when a comment is made on an object.
     * 
     * @param {string} appKey - App key.
     * @param {string} user - User object.
     * @param {string} modelKey - Key of the object's model type.
     * @param {string} objectKey - Key of the object.
     * @param {string} objectTitle - Title of the object.
     * @returns {headline} - New headline record.
    */
    async addEventAddedHeadline(appKey, user, eventKey, eventTitle, eventStartDate, eventEndDate) {

        const now = Timestamp.now();

        const key = generateKey();

        const headline = {
            key: key,
            type: "EVENTADDED",
            appKey: appKey,
            userKey: user.key,
            username: user.username,
            eventKey: eventKey,
            eventTitle: eventTitle,
            eventStartDate: eventStartDate,
            eventEndDate: eventEndDate,
            dateCreated: now
        };

        await setDoc(doc(db, collections.headlines, key), headline);

        activity.log(key, 'writes', 1);

        return headline;
    }

    /**
     * Method to add a new headline when a document is added.
     * 
     * @param {string} appKey - App key.
     * @param {string} user - User object.
     * @param {string} fileName - Name of the file that was attached.
     * @param {string} fileUrl - Url of the file that was attached.
     * @param {string} modelKey - Key of the object's model type.
     * @param {string} objectKey - Key of the object.
     * @param {string} objectTitle - Title of the object.
     * @returns {headline} - New headline record.
    */
    async addDocumentHeadline(appKey, user, fileName, fileUrl, modelKey, objectKey, objectTitle) {

        const now = Timestamp.now();

        const key = generateKey();

        const headline = {
            key: key,
            type: "DOCADDED",
            appKey: appKey,
            userKey: user.key,
            username: user.username,
            fileName: fileName,
            fileUrl: fileUrl,
            modelKey: modelKey,
            objectKey: objectKey,
            objectTitle: objectTitle,
            dateCreated: now
        };

        await setDoc(doc(db, collections.headlines, key), headline);

        activity.log(key, 'writes', 1);

        return headline;
    }

    /**
     * Method to add a new headline.
     * 
     * @param {string} key - The new key of the new headline.
     * @param {string} line - Line to display.
     * @returns {headline} - New headline record.
    */
    async addHeadline(appKey, line) {

        const now = Timestamp.now();

        const key = generateKey();

        const headline = {
            key: key,
            appKey: appKey,
            line: line,
            type: "UPDATE",
            dateCreated: now
        };

        await setDoc(doc(db, collections.headlines, key), headline);

        activity.log(key, 'writes', 1);

        return headline;
    }

    /**
     * Fetches headlines for an app.
     * 
     * @param {string} appKey - App key.
     * @returns {Promise<Array>} A promise that resolves to an array of headlines.
     */
    async fetchHeadlines(appKey) {
        try {
            // Fetch all headlines for the app
            const headlinesQuery = query(
                collection(db, collections.headlines),
                where("appKey", "==", appKey)
            );
            const snapshot = await getDocs(headlinesQuery);
            const headlines = snapshot.docs.map(doc => ({ key: doc.id, ...doc.data() }));

            return headlines;
        } catch (error) {
            console.error('Error fetching headlines:', error);
            throw error;
        }
    }

    /**
      * Fetches headlines and subscribes to real-time updates.
      * 
      * @param {string} appKey - App key.
      * @param {function} onUpdate - Callback function that handles the update.
      */
    fetchHeadlinesAndSubscribe(appKey, onUpdate) {

        try {
            // Create a reference to the headlines collection
            const headlinesCollection = collection(db, collections.headlines);

            const q = query(headlinesCollection,
                where("appKey", "==", appKey),
                orderBy("dateCreated", "desc"));

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q, snapshot => {
                const headlines = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Call the onUpdate callback with the updated list
                if (onUpdate) {
                    onUpdate(headlines);
                    activity.log(appKey, 'reads', headlines.length);
                }
            }, error => {
                console.error("Error fetching headlines:", error);
            });

            // Return the unsubscribe function to allow the caller to unsubscribe later
            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error; // Rethrow the error to handle it in the calling function
        }
    }

    buildUserToken(userKey, text) {
        return `{type: "USER", userKey: "${userKey}", text: "${text}"}`
    }

    buildObjectToken(modelKey, objectKey, text) {
        return `{type: "OBJECT", modelKey: "${modelKey}", objectKey: "${objectKey}", text: "${text}"}`
    }

    buildFileToken(modelKey, objectKey, fileName, fileUrl) {
        return `{type: "FILE", modelKey: "${modelKey}", objectKey: "${objectKey}", text: "${fileName}", fileUrl: "${fileUrl}"}`
    }

    buildModelToken(modelKey, text) {
        return `{type: "MODEL", modelKey: "${modelKey}", text: "${text}"}`
    }

    buildPhotoToken(modelKey, objectKey, url) {
        return `{type: "PHOTO", modelKey: "${modelKey}", objectKey: "${objectKey}", url: "${url}"}`
    }

    /**
     * Deletes a headline from the Firestore database.
     * 
     * @param {string} key - Headline key.
     */
    async delete(key) {
        try {

            await deleteDoc(doc(db, collections.headlines, key));

        } catch (error) {
            console.error('Error deleting headline:', error);
            // Return an error message
            return { success: false, message: "Error deleting headline." };
        }
    }

    /**
     * Deletes any document related headlines for the object.
     * 
     * @param {string} objectKey - Object key.
     */
    async deleteDocumentHeadlines(objectKey) {
        try {
            const headlinesRef = collection(db, collections.headlines);
            const q = query(
                headlinesRef,
                where("objectKey", "==", objectKey),
                where("type", "==", "DOCADDED")
            );

            const querySnapshot = await getDocs(q);

            const deletePromises = querySnapshot.docs.map(doc => deleteDoc(doc.ref));
            await Promise.all(deletePromises);

            console.log("Associated headlines deleted successfully.");
        } catch (error) {
            console.error("Error deleting associated headlines:", error);
        }
    }

    /**
     * Deletes any gallery related headlines for the object.
     * 
     * @param {string} objectKey - Object key.
     */
    async deleteGalleryHeadlines(objectKey) {
        try {
            const headlinesRef = collection(db, collections.headlines);
            const q = query(
                headlinesRef,
                where("objectKey", "==", objectKey),
                where("type", "==", "GALLERYADDED")
            );

            const querySnapshot = await getDocs(q);

            const deletePromises = querySnapshot.docs.map(doc => deleteDoc(doc.ref));
            await Promise.all(deletePromises);

            console.log("Associated headlines deleted successfully.");
        } catch (error) {
            console.error("Error deleting associated headlines:", error);
        }
    }

    /**
     * Updates the image urls for a headline.
     * 
     * @param {string} objectKey - Object key.
     * @param {string[]} photoUrls - Photo urls.
     */
    async updateObjectGalleryHeadline(objectKey, photoUrls) {

        // If there are no photo urls, delete the headline
        if (photoUrls.length === 0) {
            await this.deleteGalleryHeadlines(objectKey);
            return;
        }
        
        // Update the headline photo URLs
        const headlinesQuery = query(
            collection(db, collections.headlines),
            where('objectKey', '==', objectKey),
            where('type', '==', 'GALLERYADDED')
        );

        const headlinesSnapshot = await getDocs(headlinesQuery);

        const batch = writeBatch(db);

        headlinesSnapshot.forEach((doc) => {
            batch.update(doc.ref, { photoUrls: photoUrls });
        });

        await batch.commit();
        console.log('Headlines updated successfully');
    }

}

export default HeadlineManager;
