import React, { useState } from 'react';

// Styles
import './ForumPostMenu.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DeleteIcon from '../../../svg/DeleteIcon';
import PencilIcon from '../../../svg/PencilIcon';

// Components
import ContextMenuButton from '../../../components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../../../components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../../components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../../components/contextmenu/ContextMenuPopup';

const ForumPostMenu = ({ onEditClick, onDeleteClick }) => {
    const { theme } = useTheme();

    // State Variables
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

    /**
     * Toggles visibility of the context menu.
     */
    const toggleMenu = (event) => {
        if (!menuVisible) {
            event.preventDefault();
            setMenuPosition({ x: event.clientX, y: event.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    };

    /**
     * Handles click on the delete option.
     */
    const handleDelete = async () => {
        setMenuVisible(false);
        onDeleteClick();
    };

    /**
     * Handles click on the edit mode option.
     */
    const handleEdit = async () => {
        setMenuVisible(false);
        onEditClick();
    };

    return (
        <>
            {/* CONTAINER */}
            <div className="forum-post-menu-outer-container">

                {/* MENU BUTTON */}
                <div className="forum-post-menu-container">
                    <ContextMenuButton
                        title="Forum Post Options"
                        onToggle={toggleMenu}
                    />
                </div>

                {/* MENU */}
                <ContextMenuPopup
                    visible={menuVisible}
                    setVisible={setMenuVisible}
                    backgroundColor={theme.backgroundColorFaded}
                    width={190}
                    position={menuPosition}>

                    {/* POST OPTIONS */}
                    <ContextMenuGroup title="Post Options">

                        {/* EDIT */}
                        <ContextMenuOption
                            icon={PencilIcon}
                            text="Edit"
                            onClick={handleEdit}
                        />

                        {/* DELETE */}
                        <ContextMenuOption
                            icon={DeleteIcon}
                            text="Delete"
                            onClick={handleDelete}
                        />

                    </ContextMenuGroup>

                </ContextMenuPopup>

            </div>
        </>
    );
};

export default ForumPostMenu;
