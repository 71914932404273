import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detections
import { isMobile } from 'react-device-detect';

// Styles
import './Collections.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import Search from '../../../common/search/Search';
import CollectionSearchButton from './collectionsearchbutton/CollectionSearchButton';
import CollectionAddButton from './collectionaddbutton/CollectionAddButton';
import CollectionAdd from './collectionadd/CollectionAdd';
import CollectionItem from './collectionitem/CollectionItem';

// Utilities
import { FormMode } from '../../../common/utilities/Constants';

// Managers
import ObjectManager from '../../../common/managers/ObjectManager';

const objectManager = new ObjectManager();

/**
 * Collections Component
 * 
 * This component renders a collection-related menu items in the menu.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Collections = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        models,
        resetVisibility,
        selectedApp,
        selectedModel,
        setCollectionAddOpen,
        setFormMode,
        setObjectsView,
        setSelectedModel,
        setSelectedObject,
        userRole,
    } = useContext(Global);

    // State Variables
    const [searchVisible, setSearchVisible] = useState(false);

    /**
     * Handles a collection click event.
     * 
     * @param {object} collection - Collection that was clicked on.
     */
    const handleCollectionClick = (visibilitySetter) => {
        resetVisibility();
        // Show the add form on the desktop
        if (!isMobile) {
            setFormMode(FormMode.ADD);
        }
        visibilitySetter(true);
    };

    /**
     * Handles an add collection button click event.
     */
    const handleAddCollectionClick = () => {
        setCollectionAddOpen(true);
    };

    const handleSearchClick = () => {
        setSearchVisible(true);
    };

    const onSearch = async (entry) => {

        // Reset the visibility
        resetVisibility();

        // Make sure the form is selected instead of table
        setObjectsView("LIST");

        // Find the model in the models array and set it to active
        const model = models.find(model => model.key === entry.modelKey);
        setSelectedModel(model);

        // Fetch the selected object and set it to active
        const object = await objectManager.fetch(selectedApp.key, entry.modelKey, entry.objectKey);
        setSelectedObject(object);

        // Make sure we're in edit mode if admin
        setFormMode(userRole === "ADMIN" ? FormMode.EDIT : FormMode.VIEW);

        // Close the search dialog
        setSearchVisible(false);
    };

    return (
        <>
            {/* TITLE */}
            <div className="collections-group-title"
                style={{
                    color: theme.foregroundColorFaded,
                    fontFamily: selectedApp.fontFamily
                }}>
                Collections
            </div>

            {/* COLLECTION LIST */}
            <div className="collections-group">

                {/* SEARCH BUTTON */}
                <CollectionSearchButton
                    isActive={false}
                    onClick={() => handleSearchClick()} />

                {/* COLLECTIONS */}
                {models.map(model => (
                    <CollectionItem
                        key={model.key}
                        model={model}
                        title={model.title}
                        isActive={selectedModel && selectedModel.key === model.key}
                        onClick={() => handleCollectionClick(() => setSelectedModel(model))} />
                ))}
                
                {/* ADD COLLECTION */}
                {userRole === "ADMIN" &&
                    <>
                        {/* BUTTON */}
                        <CollectionAddButton
                            onClick={handleAddCollectionClick}
                        />

                        {/* FORM */}
                        <CollectionAdd />
                    </>
                }

            </div>

            {/* SEARCH FORM */}
            <Search
                onSearch={onSearch}
                isVisible={searchVisible}
                setVisible={setSearchVisible}
            />
        </>
    );
};

export default Collections;
