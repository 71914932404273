import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './VideoChatUser.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import UserDot from '../../../common/components/userdot/UserDot';

const VideoChatUser = ({ appUser }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        selectedApp,
    } = useContext(Global);

    return (

        <>
        {/* CONTAINER */}
        <div className="video-chat-user-container">

            {/* DOT */}
            <div className="video-chat-user-dot"
                style={{
                    color: theme.foregroundColor
                }}>
                <UserDot
                    letter={appUser.username && appUser.username.toUpperCase()[0]}
                    size="16"
                />
            </div>

            {/* USERNAME */}
            <div className="video-chat-user-name"
                style={{
                    color: theme.foregroundColor,
                    fontFamily: selectedApp.fontFamily
                }}>
                {appUser.username}
            </div>
            
        </div>
    </>
        
    );
};

export default VideoChatUser;
