import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './AppSettingsButton.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import SettingsIcon from '../svg/SettingsIcon';

const AppSettingsButton = ({ onClick }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        isMobile,
    } = useContext(Global);

    return (
        <div className="app-settings-button-container"
            onClick={onClick}>
            <SettingsIcon
                color={isMobile ? theme.foregroundColor : theme.foregroundColorFaded}
                width="20"
                height="20"
            />
        </div>
    );
};

export default AppSettingsButton;
