import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './Gallery.css';

// Images
import BackIcon from '../../../../common/svg/BackIcon';
import ForwardIcon from '../../../../common/svg/ForwardIcon';
import GalleryIcon from '../../../../common/svg/GalleryIcon';
import PlusIcon from '../../../../common/svg/PlusIcon';
import RemoveIcon from '../../../../common/svg/RemoveIcon';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import FieldMenu from '../../fieldmenu/FieldMenu';

/**
 * Gallery Component
 * 
 * This component renders a gallery of photos.
 * 
 * @param {object} object - The object containing field values.
 * @param {object} field - The field metadata.
 * @param {function} onUpdate - The function to call with updated values.
 * @param {boolean} showFieldMenu - Indicates whether the menu should be shown.
 * @param {boolean} readOnly - Indicates whether the input should be read-only.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Gallery = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
    readOnly = false
}) => {
    const { theme, hexToRgba } = useTheme();
    const {
        userRole
    } = useContext(Global);

    // State Variables
    const [value, setValue] = useState([]); // Default ADD mode value
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    // References
    const dragCounter = useRef(0);
    const fileInputRef = useRef(null);
    const containerRef = useRef(null);
    const initialValueRef = useRef('');

    /**
     * Initializes the field value.
     */
    useEffect(() => {
        const initialValue = object?.[field.key] || '';
        setValue(initialValue);
        initialValueRef.current = initialValue;
    }, [object, field.key]);

    /**
     * Handles adding of photos to the gallery.
     * 
     * @param {event} event - Event object.
     */
    const handleAddPhotos = (event) => {
        const files = event.target.files || event.dataTransfer.files;
        if (!files.length) return;

        const newPhotos = Array.from(files).map((file) => ({
            url: URL.createObjectURL(file),
        }));

        const updatedValue = [...value, ...newPhotos];
        setValue(updatedValue);

        // Call onUpdate since we have added new photos with "blob:" URLs
        onUpdate(field, updatedValue);

        fileInputRef.current.value = "";
    };

    /**
     * Handles removal of the current photo from the gallery.
     */
    const handleRemovePhoto = () => {
        const updatedValue = value.filter((_, index) => index !== currentIndex);
        setValue(updatedValue);
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));

        // Call onUpdate since a photo has been removed
        onUpdate(field, updatedValue);
    };

    /**
     * Handles navigating to the previous photo.
     */
    const handlePrevious = (e) => {
        e.stopPropagation();
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    /**
     * Handles navigating to the next photo.
     */
    const handleNext = (e) => {
        e.stopPropagation();
        setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, value.length - 1));
    };

    /**
     * Handles click of the add button.
     */
    const handleAddPhotosClick = () => {
        if (readOnly) return;
        fileInputRef.current.click();
    };

    /**
     * Cancels events when dragging files into the component.
     */
    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    /**
     * Handles dropping of photos into the component.
     * 
     * @param {event} event - Event object.
     */
    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
        dragCounter.current = 0;
        handleAddPhotos(event);
    };

    /**
     * Handles drag enter of the gallery while dragging files.
     * 
     * @param {event} event - Event object.
     */
    const handleDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
        dragCounter.current += 1;
        setIsDragging(true);
    };

    /**
     * Handles drag leave of the gallery while dragging files.
     * 
     * @param {event} event - Event object.
     */
    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        dragCounter.current -= 1;
        if (dragCounter.current === 0) {
            setIsDragging(false);
        }
    };

    const menuOptions = [
        {
            text: 'Add Photo(s)',
            icon: PlusIcon,
            onClick: () => {
                handleAddPhotosClick();
            }
        },
        // Conditionally include the "Remove Video" option
        ...(value[currentIndex]?.url ? [{
            text: 'Remove Item',
            icon: RemoveIcon,
            onClick: () => {
                handleRemovePhoto();
            }
        }] : []),
    ];

    return (
        <>
            <div className="gallery-outer-container" onClick={handleAddPhotosClick}>

                {/* CONTAINER */}
                <div
                    ref={containerRef}
                    className="gallery-container"
                    style={{
                        backgroundColor: 'transparent',
                        borderColor: theme.backgroundColorFaded,
                        borderWidth: isDragging ? '2px' : '1px',
                    }}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}>

                    {/* GALLERY */}
                    <div className="gallery-wrapper">
                        {value[currentIndex]?.url ? (
                            <img src={value[currentIndex].url} alt="Upload Preview" className="gallery-image" />
                        ) : (
                            <div>
                                <GalleryIcon
                                    color={theme.backgroundColorFaded}
                                    width="100"
                                    height="100"
                                />
                            </div>
                        )}

                        {/* FILE INPUT */}
                        <input
                            ref={fileInputRef}
                            id="image-upload"
                            type="file"
                            accept="image/*"
                            onChange={(event) => handleAddPhotos(event)}
                            style={{ display: 'none' }}
                            aria-label="Image Upload"
                            multiple
                        />
                    </div>

                    {/* PREVIOUS BUTTON */}
                    {currentIndex > 0 && (
                        <div
                            className="gallery-nav-button previous-button"
                            style={{ backgroundColor: hexToRgba(theme.backgroundColor, 0.5) }}
                            onClick={handlePrevious}>
                            <BackIcon
                                color={theme.foregroundColor}
                                width="20"
                                height="20"
                            />
                        </div>
                    )}

                    {/* NEXT BUTTON */}
                    {currentIndex < value.length - 1 && (
                        <div
                            className="gallery-nav-button next-button"
                            style={{ backgroundColor: hexToRgba(theme.backgroundColor, 0.5) }}
                            onClick={handleNext}>
                            <ForwardIcon
                                color={theme.foregroundColor}
                                width="20"
                                height="20"
                            />
                        </div>
                    )}

                    {/* FIELD MENU */}
                    {userRole === "ADMIN" && showFieldMenu &&
                        <div
                            className="gallery-field-menu"
                            style={{ backgroundColor: hexToRgba(theme.backgroundColor, 0.5) }}>
                            <FieldMenu
                                field={field}
                                additionalOptions={menuOptions}
                            />
                        </div>
                    }

                    {/* PHOTO COUNT */}
                    {value.length > 1 &&
                        <div className="gallery-image-count"
                            style={{
                                color: theme.foregroundColor,
                                backgroundColor: hexToRgba(theme.backgroundColor, 0.5)
                            }}>
                            {value.length > 0 && `${currentIndex + 1} of ${value.length}`}
                        </div>
                    }

                </div>

            </div>
        </>
    );
};

export default Gallery;
