import React, { useContext, useEffect, useRef } from 'react';

// Global
import { Global } from './Global';

// Constants
import { LoginMode } from './common/utilities/Constants';

// Router
import { useNavigate, useLocation } from 'react-router-dom';

// Firebase
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './firebaseConfig';

// Activity
import { activity } from './common/managers/ActivityManager';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './Root.css';

// Theme
import { useStyle, useTheme } from './ThemeContext';

// Components
import Account from './account/Account';
import Admin from './admin/Admin';
import AppAdd from './common/appadd/AppAdd';
import AppSettings from './common/appsettings/AppSettings';
import AppUsers from './common/appusers/AppUsers';
import Home from './home/Home';
import LogoLarge from './common/logolarge/LogoLarge';
import Desktop from './desktop/Desktop';
import Mobile from './mobile/Mobile';
import Welcome from './welcome/Welcome';
import Progress from './common/components/progress/Progress';

// Managers
import AppManager from './common/managers/AppManager';
import AppUserManager from './common/managers/AppUserManager';
import ChannelManager from './common/managers/ChannelManager';
import ConversationManager from './common/managers/ConversationManager';
import EventManager from './common/managers/EventManager';
import FieldManager from './common/managers/FieldManager';
import InviteManager from './common/managers/InviteManager';
import ModelManager from './common/managers/ModelManager';
import RoleManager from './common/managers/RoleManager';
import UserManager from './common/managers/UserManager';
import ElementManager from './common/managers/ElementManager';

// Styled Components
const InlineStyles = useStyle`
    
    body {
      margin: 0;
      font-family: 'Red Hat Display';
      display: flex;
      flex-direction: column;
    }

    *::-webkit-scrollbar {
      width: 8px;
    }
    
    *::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.scrollbarColor} !important;
      border-radius: 4px;
    }
    
    *::-webkit-scrollbar-thumb:hover {
      background-color: ${(props) => props.scrollbarColor} !important;
      /* Darker shade when hovering over the scrollbar thumb */
    }
    
    * {
      scrollbar-color: ${(props) => props.scrollbarColor} transparent !important;
      scrollbar-width: thin;
    }
    
    *::placeholder {
      color: ${(props) => props.placeholderColor};
      opacity: 1; /* to ensure the color is applied fully */
    }
`;

const appManager = new AppManager();
const appUserManager = new AppUserManager();
const channelManager = new ChannelManager();
const conversationManager = new ConversationManager();
const eventManager = new EventManager();
const fieldManager = new FieldManager();
const modelManager = new ModelManager();
const inviteManager = new InviteManager();
const roleManager = new RoleManager();
const userManager = new UserManager();
const elementManager = new ElementManager();

const Root = () => {
  const { theme } = useTheme();
  const {
    appAddVisible,
    appSettingsVisible,
    currentUser,
    invite,
    page,
    selectedApp,
    setAppFields,
    setAppSettingsVisible,
    setAppUsers,
    setChannels,
    setConversations,
    setCurrentUser,
    setEvents,
    setModels,
    setInvite,
    setLoginMode,
    setPage,
    setRoles,
    setSelectedApp,
    setUserElements,
    setUserRole,
    studio
  } = useContext(Global);

  // Navigate
  let navigate = useNavigate();

  // Location
  const location = useLocation();

  // Unsubscribe references for app-wide items
  const unsubscribeAppFieldsRef = useRef(null);
  const unsubscribeChannelsRef = useRef(null);
  const unsubscribeConversationsRef = useRef(null);
  const unsubscribeEventsRef = useRef(null);
  const unsubscribeModelsRef = useRef(null);
  const unsubscribeRolesRef = useRef(null);
  const unsubscribeUserElementsRef = useRef(null);

  // History
  useEffect(() => {
    const handlePopState = (event) => {
      if (!window.confirm('Are you sure you want to go back?')) {
        // Prevent navigation by pushing the current location back to the history stack
        navigate(location.pathname, { replace: true });
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate, location]);

  /**
   * INVITE CODE
   */
  useEffect(() => {

    const getQueryStringValue = (key) => {
      const queryParams = new URLSearchParams(location.search);
      return queryParams.get(key);
    };

    const inviteCode = getQueryStringValue('i');

    if (inviteCode) {

      async function fetchInvite() {
        try {
          // Log out
          await signOut(auth);

          const fetchedInvite = await inviteManager.fetchInvite(inviteCode);

          // If an invite exists, set the invite in Global and
          // redirect to the root again
          if (fetchedInvite) {

            setInvite(fetchedInvite);

            // Fetch the app
            const app = await appManager.fetchApp(fetchedInvite.appKey);
            if (app) {
              setSelectedApp(app);
            }

            // TEMP - NAVIGATE TO INVITE LANDING
            navigate('/');

          }
        } catch (error) {
          console.error('Failed to fetch invite:', error);
        }
      }
      fetchInvite();
    }
  }, [location.search, navigate, setInvite]);

  // If an invite exists, we were sent back to root without a querystring.
  // At this point, we can start processing the invite, and then clear
  // the invite when finished.
  useEffect(() => {

    if (invite) {

      async function preprocessInvite() {

        // Determine if a user with the email exists in the system yet. If not, they'll need
        // to sign up in order to continue.

        const user = await userManager.fetchUserWithEmail(invite.email);
        if (user) {

          if (!currentUser) {
            setLoginMode(LoginMode.LOGIN);
          } else {

            // User is currently signed in.

            // THE REMANING CODE HERE WILL ALSO BE RUN POST-LOGIN AND POST-SIGN IN IF
            // AN INVITE EXISTS IN STATE.

            await inviteManager.processInvite(
              currentUser,
              invite,
              setInvite,
              setSelectedApp,
              setPage
            );
          }
        } else {
          // Since the user doesn't exist yet, the process will be:
          // 1) Sign up and add user. Don't delete invite,
          //    as it will be detected post login to continue with steps 2 and 3.
          // 2) Add app user
          setLoginMode("SIGNUP");
        }
      }

      preprocessInvite();
    }
  }, [currentUser, invite, setInvite, setLoginMode, setPage, setSelectedApp]);

  /**
   * LOGIN STATUS HOOK
   */
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const combinedUser = await userManager.fetchCurrentUser(user);
          if (combinedUser) {
            setCurrentUser(combinedUser);
            setPage("HOME");
          } else {
            setPage("WELCOME");
          }
        } catch (error) {
          console.error("Error handling user:", error);
          setCurrentUser(null);
          setPage("WELCOME");
        }
      } else {
        setCurrentUser(null);
        setPage("WELCOME");
      }
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, [setCurrentUser, setPage]);

  // SET CURRENT APP USER ROLE
  useEffect(() => {

    if (!selectedApp) return;
    if (!currentUser) return;

    // TEMP UNTIL WE CAN FETCH THE USER ROLE
    setUserRole("ADMIN");

    /*

    // User role
    async function fetchUserRole() {

      // Fetch the user record
      const fetched = await appUserManager.fetchAppUser(currentUser.key, selectedApp.key);

      setUserRole(fetched.roleKey);
    }
    fetchUserRole();
    */

  }, [selectedApp, currentUser, setUserRole]);

  /**
   * LOAD APP USERS APP-WIDE
   */
  useEffect(() => {

    if (!selectedApp || !selectedApp.key) {
      setAppUsers([]);
      return;
    }

    const fetchAppUsers = async () => {
      const users = await appUserManager.fetchAppUsers(selectedApp.key);
      setAppUsers(users);
    }
    fetchAppUsers();

  }, [selectedApp, setAppUsers]);

  /**
   * LOAD MODELS APP-WIDE
   */
  useEffect(() => {

    const fetchModels = (appKey) => {

      const handleUpdate = (items) => {
        setModels(items);
        activity.log(appKey, 'reads', items.length);
      };

      const unsubscribe = modelManager.listAndSubscribe(appKey, handleUpdate);
      return unsubscribe;
    };

    if (!selectedApp || !selectedApp.key) {
      setModels([]);
      return;
    }

    unsubscribeModelsRef.current = fetchModels(selectedApp.key);

    return () => {
      if (unsubscribeModelsRef.current) {
        unsubscribeModelsRef.current();
      }
    };

  }, [selectedApp, setModels]);

  /**
   * LOAD CHANNELS APP-WIDE
   */
  useEffect(() => {

    const fetchChannels = (appKey) => {

      const handleUpdate = (items) => {
        setChannels(items);
        activity.log(appKey, 'reads', items.length);
      };

      const unsubscribe = channelManager.listAndSubscribe(appKey, handleUpdate);
      return unsubscribe;
    };

    if (!selectedApp || !selectedApp.key) {
      setChannels([]);
      return;
    }

    unsubscribeChannelsRef.current = fetchChannels(selectedApp.key);

    return () => {
      if (unsubscribeChannelsRef.current) {
        unsubscribeChannelsRef.current();
      }
    };

  }, [selectedApp, setChannels]);

  /**
   * LOAD APP FIELDS APP WIDE
   */
  useEffect(() => {

    const fetchAppFields = (appKey) => {

      const handleUpdate = (items) => {
        setAppFields(items);
        activity.log(appKey, 'reads', items.length);
      };

      const unsubscribe = fieldManager.listAppFieldsAndSubscribe(appKey, handleUpdate);
      return unsubscribe;
    };

    if (!selectedApp || !selectedApp.key) {
      setAppFields([]);
      return;
    }

    unsubscribeAppFieldsRef.current = fetchAppFields(selectedApp.key);

    return () => {
      if (unsubscribeAppFieldsRef.current) {
        unsubscribeAppFieldsRef.current();
      }
    };

  }, [selectedApp, setAppFields]);

  /**
   * LOAD USER ELEMENTS APP WIDE
   */
  useEffect(() => {

    if (!selectedApp) return;
    if (!currentUser) return;

    const fetchUserElements = (appKey, userKey) => {

      const handleUpdate = (items) => {
        setUserElements(items);
        activity.log(appKey, 'reads', items.length);
      };

      const unsubscribe = elementManager.list(appKey, userKey, handleUpdate);
      return unsubscribe;
    };

    if (!selectedApp || !selectedApp.key) {
      setUserElements([]);
      return;
    }

    unsubscribeUserElementsRef.current = fetchUserElements(selectedApp.key, currentUser.key);

    return () => {
      if (unsubscribeUserElementsRef.current) {
        unsubscribeUserElementsRef.current();
      }
    };

  }, [selectedApp, setUserElements, currentUser]);

  /**
   * LOAD ROLES APP-WIDE
   */
  useEffect(() => {

    const fetchRoles = (appKey) => {

      const handleUpdate = (items) => {
        setRoles(items);
        activity.log(appKey, 'reads', items.length);
      };

      const unsubscribe = roleManager.fetchRolesAndSubscribe(appKey, handleUpdate);
      return unsubscribe;
    };

    if (!selectedApp || !selectedApp.key) {
      setRoles([]);
      return;
    }

    unsubscribeRolesRef.current = fetchRoles(selectedApp.key);

    return () => {
      if (unsubscribeRolesRef.current) {
        unsubscribeRolesRef.current();
      }
    };

  }, [selectedApp, setRoles]);

  /**
   * LOAD CONVERSATIONS APP-WIDE
   */
  useEffect(() => {

    const fetchConversations = (appKey) => {

      const handleUpdate = (items) => {
        setConversations(items);
        activity.log(appKey, 'reads', items.length);
      };

      const unsubscribe = conversationManager.listAndSubscribe(appKey, handleUpdate);
      return unsubscribe;
    };

    if (!selectedApp || !selectedApp.key) {
      setConversations([]);
      return;
    }

    unsubscribeConversationsRef.current = fetchConversations(selectedApp.key);

    return () => {
      if (unsubscribeConversationsRef.current) {
        unsubscribeConversationsRef.current();
      }
    };

  }, [selectedApp, setConversations]);

  /**
   * LOAD EVENTS APP-WIDE
   */
  useEffect(() => {

    const fetchEvents = (appKey) => {

      const handleUpdate = (items) => {
        setEvents(items);
        activity.log(appKey, 'reads', items.length);
      };

      const unsubscribe = eventManager.listAndSubscribe(appKey, handleUpdate);
      return unsubscribe;
    };

    if (!selectedApp || !selectedApp.key) {
      setEvents([]);
      return;
    }

    unsubscribeEventsRef.current = fetchEvents(selectedApp.key);

    return () => {
      if (unsubscribeEventsRef.current) {
        unsubscribeEventsRef.current();
      }
    };

  }, [selectedApp, setEvents]);

  return (
    <>
      <InlineStyles
        scrollbarColor={theme.backgroundColorFaded}
        placeholderColor={theme.foregroundColorFaded}
      />

      {/* SPLASH SCREEN */}
      {page === 'ROOT' && (
        <div className="root-container"
          style={{ backgroundColor: studio && studio.backgroundColor ? studio.backgroundColor : 'black' }}>
          <LogoLarge />
        </div>
      )}

      {/* LOGGED IN - HOME*/}
      {page === 'HOME' && (
        <Home />
      )}

      {/* LOGGED OUT - WELCOME */}
      {page === 'WELCOME' && (
        <Welcome />
      )}

      {/* ADMIN */}
      {page === 'ADMIN' && (
        <Admin />
      )}

      {/* RUN */}
      {page === 'RUN' && (
        <>
          {isMobile ? (
            <>
              <Mobile />
            </>
          ) : (
            <>
              <Desktop />
            </>
          )}
        </>
      )}

      {/* CUSTOMERS */}
      {page === 'CUSTOMERS' && (
        <AppUsers />
      )}

      {/* PROGRESS PANEL */}
      <Progress />

      {/* ACCOUNT */}
      <Account />

      {/* APP ADD */}
      {appAddVisible &&
        <AppAdd />
      }

      {/* SETTINGS DIALOG */}
      {selectedApp &&
        <AppSettings
          appKey={selectedApp.key}
          appSettingsVisible={appSettingsVisible}
          setAppSettingsVisible={setAppSettingsVisible}
        />
      }

    </>
  );
};

export default Root;

