import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Utilities
import { generateKey } from '../../../utilities/Keys';
import { ChannelType } from '../../../utilities/Constants';

// Styles
import './ChannelAdd.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import ChannelPrivacy from '../channelprivacy/ChannelPrivacy';
import ChannelTypes from '../channeltypes/ChannelTypes';
import Modal from '../../../components/modal/Modal';

// Managers
import ChannelManager from '../../../managers/ChannelManager';

const channelManager = new ChannelManager();

/**
 * ChannelAdd Component
 * 
 * This component allows the user to add a channel.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ChannelAdd = () => {
    const { theme } = useTheme();
    const {
        channelAddOpen,
        channels,
        currentUser,
        hideProgress,
        resetVisibility,
        selectedApp,
        setChannelAddOpen,
        setChatVisible,
        setForumVisible,
        setSelectedChannel,
        setStatusMessage,
        setVideoChatVisible,
        showProgress
    } = useContext(Global);

    // State Variables
    const [title, setTitle] = useState('');
    const [selectedType, setSelectedType] = useState(ChannelType.TEXT);
    const [isPrivate, setPrivate] = useState(false);

    // References
    const titleInputRef = useRef(null);

    // Focus the title field on channel load
    useEffect(() => {
        if (channelAddOpen) {
            titleInputRef.current.focus();
        }
    }, [channelAddOpen]);

    /**
     * Handles the add of a channel.
     * 
     * @param {string} e - Click event.
     */
    const handleAdd = async () => {

        if (!title) {
            alert('Please enter a channel title.');
            return;
        }

        showProgress("Adding channel...");

        setChannelAddOpen(false);

        const userKey = currentUser.key;
        const username = currentUser.username;
        const appKey = selectedApp.key;

        const newKey = generateKey();  // Generate key for the new channel

        // Current timestamp
        const now = Timestamp.now();

        // Initialize the highest sort number to 0
        let highestSortNumber = 0;

        // Iterate through each channel to find the highest sort number
        channels.forEach(channel => {
            const sortNumber = channel.sort;
            if (sortNumber > highestSortNumber) {
                highestSortNumber = sortNumber;
            }
        });

        const channelData = {
            key: newKey,
            appKey: appKey,
            description: "",
            private: isPrivate,
            sort: highestSortNumber + 1,
            title: title,
            type: selectedType,
            userKey: userKey,
            username: username,
            dateCreated: now,
            dateModified: now,
        };

        await channelManager.add(appKey, newKey, channelData);
        
        resetVisibility();
        setSelectedChannel(channelData);

        if (selectedType === ChannelType.TEXT) { setChatVisible(true) } 
        if (selectedType === ChannelType.FORUM) { setForumVisible(true) } 
        if (selectedType === ChannelType.VIDEO) { setVideoChatVisible(true) } 

        setTitle('');

        setStatusMessage("Channel created successfully.");

        hideProgress();
    };

    /**
     * Handles toggle of the private setting.
     * 
     * @param {boolean} newValue - The new privacy setting (true for private, false for public).
     */
    const handleTogglePrivate = (newValue) => {
        setPrivate(newValue);
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="New Channel"
                isOpen={channelAddOpen}
                onClose={() => setChannelAddOpen(false)}
                width="330px">

                {/* TITLE */}
                <div className="channel-add-label"
                    style={{ color: theme.foregroundColor }}>
                    Title
                </div>
                <input
                    type="text"
                    name="title"
                    className="channel-add-input"
                    style={{
                        color: theme.foregroundColor,
                        backgroundColor: theme.backgroundColorFaded,
                        borderColor: theme.backgroundColorFaded,
                        fontFamily: selectedApp.fontFamily
                    }}
                    value={title}
                    maxLength="100"
                    onChange={e => setTitle(e.target.value)}
                    ref={titleInputRef}
                />

                {/* CHANNEL TYPE */}
                <div className="channel-add-label"
                    style={{ color: theme.foregroundColor }}>
                    Channel Type
                </div>
                <ChannelTypes
                    selectedType={selectedType}
                    setSelectedType={setSelectedType}
                />

                {/* PRIVATE */}
                <div className="channel-add-privacy-section">
                    <ChannelPrivacy
                        onChange={handleTogglePrivate}
                        defaultValue={isPrivate}
                    />
                </div>

                {/* BUTTONS */}
                <div className="channel-add-buttons">
                    <button
                        type="button"
                        onClick={handleAdd}
                        className="channel-add-button"
                        style={{
                            color: theme.foregroundColor,
                            fontFamily: selectedApp.fontFamily
                        }}>
                        Add Channel
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default ChannelAdd;
