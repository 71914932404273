import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detections
import { isMobile, isTablet } from 'react-device-detect';

// Utilities
import { FormMode } from '../../../common/utilities/Constants';

// Styles
import './NavBar.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import CalendarIcon from '../../../common/svg/CalendarIcon';
import HomeIcon from '../../../common/svg/HomeIcon';
import MessagesIcon from '../../../common/svg/MessagesIcon';
import UsersIcon from '../../../common/svg/UsersIcon';

// Components
import AppSettingsButton from '../../appsettingsbutton/AppSettingsButton';

/**
 * NavBar Component
 * 
 * This component renders a nav button bar.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const NavBar = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    resetVisibility,
    setAppSettingsVisible,
    userRole,
    setAppUsersVisible,
    setCalendarVisible,
    setFormMode,
    setMessagesVisible,
    setRunHomeVisible
  } = useContext(Global);

  const handleItemClick = (visibilitySetter) => {
    resetVisibility();
    // Show the add form on the desktop
    if (!isMobile) {
      setFormMode(FormMode.ADD);
    }
    visibilitySetter(true);
  };

  return (
    <>
      <div className="nav-bar-container">

        {/* SETTINGS BUTTON */}
        {(userRole === 'ADMIN' && !isMobile) &&
          <AppSettingsButton
            onClick={() => setAppSettingsVisible(true)}
          />
        }

        {/* HOME */}
        <div className="nav-bar-button"
          onClick={() => handleItemClick(setRunHomeVisible)}
          title="Home">
          <HomeIcon
            color={isMobile ? theme.foregroundColor : theme.foregroundColorFaded}
            width="18"
            height="18"
          />
        </div>

        {/* CALENDAR */}
        <div className="nav-bar-button"
          onClick={() => handleItemClick(setCalendarVisible)}
          title="Calendar">
          <CalendarIcon
            color={isMobile ? theme.foregroundColor : theme.foregroundColorFaded}
            width="17"
            height="17"
          />
        </div>

        {/* MESSAGES */}
        <div className="nav-bar-button"
          onClick={() => handleItemClick(setMessagesVisible)}
          title="Messages">
          <MessagesIcon
            color={isMobile ? theme.foregroundColor : theme.foregroundColorFaded}
            width="20"
            height="20"
          />
        </div>

        {/* USERS */}
        <div className="nav-bar-button"
          onClick={() => handleItemClick(setAppUsersVisible)}
          title="App Members">
          <UsersIcon
            color={isMobile ? theme.foregroundColor : theme.foregroundColorFaded}
            width="19"
            height="19"
          />
        </div>

      </div>
    </>
  );
};

export default NavBar;

