// Firebase
import { collection, deleteDoc, doc, getDocs, query, setDoc, Timestamp, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Utilities
import { generateKey } from '../utilities/Keys';

// Managers
import { activity } from './ActivityManager';
import AppManager from './AppManager';
import AppUserManager from './AppUserManager';
import HeadlineManager from './HeadlineManager';
import UserIndexManager from './UserIndexManager';

const appManager = new AppManager();
const appUserManager = new AppUserManager();
const headlineManager = new HeadlineManager();
const userIndexManager = new UserIndexManager();

class InviteManager {

    /**
     * Method to add a new invite.
     * 
     * @param {string} key - The new key of the invite.
     * @param {string} data - App data
     * @returns {invite} - New invite record.
    */
    async addInvite(key, data) {

        await setDoc(doc(db, collections.invites, key), data);

        activity.log(key, 'writes', 1);

        return data;
    }

    /**
     * Fetches an invite.
     * 
     * @param {string} key - Invite key.
     * @returns {Promise<Object|null>} A promise that resolves to the invite object if found, or null if not found.
    */
    async fetchInvite(key) {
        try {
            const inviteCollection = collection(db, collections.invites);
            const q = query(inviteCollection, where("key", "==", key));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching invite:", error);
            throw error;
        }
    }

    /**
     * Deletes an invite from the Firestore database.
     * 
     * @param {string} key - Invite key.
     */
    async deleteInvite(key) {
        try {

            await deleteDoc(doc(db, collections.invites, key));

        } catch (error) {
            console.error('Error deleting invite:', error);
            // Return an error message
            return { success: false, message: "Error deleting invite." };
        }
    }

    async processInvite(user, invite, setInvite, setSelectedApp, setPage) {
        // Detect if the user is already an app user.
        const appUser = await appUserManager.fetchAppUser(user.key, invite.appKey);

        // Current timestamp
        const now = Timestamp.now();

        // If not, add the app user.
        if (!appUser) {
            const appUserKey = generateKey();
            const appUserData = {
                key: appUserKey,
                appKey: invite.appKey,
                userKey: user.key,
                username: user.username,
                email: user.email,
                roleKey: invite.roleKey,
                dateJoined: now
            };

            // Add the app user
            await appUserManager.add(invite.appKey, appUserKey, appUserData);

            // Add the user to the user index for the app
            await userIndexManager.add(invite.appKey, user);
        }

        // Fetch the app and set it as the selected app
        const app = await appManager.fetchApp(invite.appKey);
        if (app) {
            setSelectedApp(app);

            // Navigate to RUN
            setPage('RUN');

            // Clear the invite
            await this.deleteInvite(invite.key);
            setInvite(null);

            // Add a headline
            headlineManager.addJoinedHeadline(app.key, user);
        }
    }
}

export default InviteManager;
