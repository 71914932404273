import React, { useEffect, useRef, useState } from 'react';
import Peer from 'simple-peer';

// Styles
import './VideoChat.css';

// Utilities
import { generateKey } from './common/utilities/Keys';

const VideoChat = ({ key }) => {

  // Keeps track of whether this component was the initiator
  const [initiator, setInitiator] = useState(false);

  // Keeps track of the local stream
  const [stream, setStream] = useState(null);

  // Get the user's video and audio stream and generate a key for 
  // the component when it mounts
  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then((mediaStream) => {
        setStream(mediaStream);
        if (localVideoRef.current) {
          localVideoRef.current.srcObject = mediaStream;
        }
      })
      .catch((err) => console.error('Failed to get local stream', err));
  }, []);









  const [peerSignal, setPeerSignal] = useState('');
  const [peerConnected, setPeerConnected] = useState(false);

  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);

  const peerRef = useRef(null);

  const createPeer = (isInitiator) => {
    setInitiator(isInitiator);

    const peer = new Peer({
      initiator: isInitiator,  // True for the initiator (offerer), false for the receiver (answerer)
      trickle: false,
      stream: stream,
    });

    peer.on('signal', (data) => {
      setPeerSignal(JSON.stringify(data));  // This signal should be sent to the remote peer
    });

    peer.on('stream', (remoteStream) => {
      if (remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = remoteStream;
      }
    });

    peer.on('connect', () => {
      console.log('Peer connected!');
      setPeerConnected(true);
    });

    peerRef.current = peer;
  };

  const acceptSignal = (signalData) => {
    if (peerRef.current) {
      peerRef.current.signal(JSON.parse(signalData));
    }
  };

  return (
    <div>
      <div style={{ color: 'white' }}>
        Key: {key}
      </div>
      <div style={{ color: 'white' }}>
        Initiator: {initiator ? "True" : "False"}
      </div>

      <div className="test-video-chat-videos">
        <video ref={localVideoRef} autoPlay muted />
        <video ref={remoteVideoRef} autoPlay />
      </div>


      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {!peerConnected && (
          <>
            <button onClick={() => createPeer(true)}>Call</button>
            <textarea
              rows="10"
              cols="50"
              value={peerSignal || ''}
              readOnly
              placeholder="Signal Data"
            />
          </>
        )}

        {peerConnected && (
          <p>Peer connected! You should now be able to see the video.</p>
        )}
      </div>

      <div>
        <h3>Enter Remote Signal</h3>
        <textarea
          rows="10"
          cols="50"
          onChange={(e) => acceptSignal(e.target.value)}
          placeholder="Paste remote signal data here"
        />
      </div>
    </div>
  );
};

export default VideoChat;
