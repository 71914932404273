import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './AppSettingsTitle.css';

// Theme
import { useTheme } from '../../ThemeContext';

const AppSettingsTitle = ({title, setTitle, onBlur}) => {
    const { theme } = useTheme();
    const {
        selectedApp
    } = useContext(Global);

    const [currentCount, setCurrentCount] = useState(0);
    const maxCount = 40;

    useEffect(() => {
        if (!selectedApp) return;
        setCurrentCount(title.length);
    }, [title, selectedApp]);

    const handleChange = (e) => {
        const text = e.target.value;
        setTitle(text);
        setCurrentCount(text.length);
    };

    const handleBlur = (e) => {
        const text = e.target.value;
        if (onBlur) onBlur(text);
    };

    return (

        <div className="app-settings-title-container">
            <div 
                className="app-settings-title-label" 
                style={{
                    color: theme.foregroundColorFaded,
                    fontFamily: selectedApp.fontFamily
                }}>
                Title
            </div>
            <input
                type="text"
                name="title"
                className="app-settings-title-input"
                style={{
                    color: theme.foregroundColor,
                    backgroundColor: theme.backgroundColorFaded,
                    borderColor: theme.backgroundColorFaded,
                    fontFamily: selectedApp.fontFamily || 'Open Sans'
                }}
                maxLength={maxCount}
                value={title}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Title"
            />
            <div 
                className="app-settings-title-count" 
                style={{ 
                    color: theme.foregroundColorFaded, 
                    fontFamily: selectedApp.fontFamily
                }}>
                {`${currentCount}/${maxCount}`}
            </div>
        </div>

    );
};

export default AppSettingsTitle;
