import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './Channels.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import ChannelAddButton from './channeladdbutton/ChannelAddButton';
import ChannelItem from './channelitem/ChannelItem';
import ChannelAdd from './channeladd/ChannelAdd';

// Utilities
import { ChannelType } from '../../utilities/Constants';

/**
 * Channels Component
 * 
 * This component renders a channel-related menu items in the menu.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Channels = () => {
    const { theme } = useTheme();
    const {
        channels,
        resetVisibility,
        selectedApp,
        selectedChannel,
        setChannelAddOpen,
        setChatVisible,
        setForumVisible,
        setSelectedChannel,
        setVideoChatVisible,
        userRole,
    } = useContext(Global);

    /**
     * Handles a channel click event.
     * 
     * @param {object} channel - Channel that was clicked on.
     */
    const handleChannelClick = (channel) => {
        resetVisibility();
        setSelectedChannel(channel);
        if (channel.type === ChannelType.TEXT) { setChatVisible(true) } 
        if (channel.type === ChannelType.FORUM) { setForumVisible(true) } 
        if (channel.type === ChannelType.VIDEO) { setVideoChatVisible(true) } 
    };
    
    /**
     * Handles an add channel button click event.
     */
    const handleAddChannelClick = () => {
        setChannelAddOpen(true);
    };

    return (
        <>
            {/* TITLE */}
            <div className="channels-group-title"
                style={{
                    color: theme.foregroundColorFaded,
                    fontFamily: selectedApp.fontFamily
                }}>
                Channels
            </div>

            {/* CHANNEL LIST */}
            <div className="channels-group">
                {channels.map(channel => (
                    <ChannelItem
                        key={channel.key}
                        channel={channel}
                        isActive={selectedChannel && selectedChannel.key === channel.key}
                        onClick={() => handleChannelClick(channel)}
                    />
                ))}

                {/* ADD CHANNEL */}
                {userRole === "ADMIN" &&
                    <>
                        {/* BUTTON */}
                        <ChannelAddButton
                            onClick={handleAddChannelClick}
                        />

                        {/* FORM */}
                        <ChannelAdd />
                    </>
                }
            </div>
        </>
    );
};

export default Channels;
