import React from 'react';

// Styles
import './MenuHeader.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AppLogo from '../../common/applogo/AppLogo';
import AppTitle from '../../common/apptitle/AppTitle';
import { isMobile } from 'react-device-detect';

const MenuHeader = () => {
    const { theme } = useTheme();

    return (

        <div className="menu-header-container"
            style={{
                backgroundColor: theme.backgroundColor, 
                color: theme.foregroundColor
            }}>
            <AppTitle />
        </div>
    );
};

export default MenuHeader;
