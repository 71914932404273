import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { doc, updateDoc } from 'firebase/firestore';
import { collections, db } from '../../../../firebaseConfig';

// Activity
import { activity } from '../../../../common/managers/ActivityManager';

// Styles
import './CollectionItemMenu.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DotsVerticalIcon from '../../../../common/svg/DotsVerticalIcon';

// Images
import DeleteIcon from '../../../../common/svg/DeleteIcon';
import DownIcon from '../../../../common/svg/DownIcon';
import RenameIcon from '../../../../common/svg/RenameIcon';
import UpIcon from '../../../../common/svg/UpIcon';

// Components
import ContextMenuButton from '../../../../common/components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../../../../common/components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../../../common/components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../../../common/components/contextmenu/ContextMenuPopup';
import Modal from '../../../../common/components/modal/Modal';

// Managers
import ModelManager from '../../../../common/managers/ModelManager';

const modelManager = new ModelManager();

const CollectionItemMenu = ({ model, isActive = false }) => {
    const { theme } = useTheme();
    const {
        models,
        hideProgress,
        selectedApp,
        setFormFields,
        showProgress
    } = useContext(Global);

    // State Variables
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [renameOpen, setRenameOpen] = useState(false);
    const [modelTitle, setModelTitle] = useState('');

    const toggleMenu = (event) => {
        if (!menuVisible) {
            event.preventDefault();
            setMenuPosition({ x: event.clientX, y: event.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    };

    useEffect(() => {
        if (!model) return;
        setModelTitle(model.title);
    }, [model]);

    const handleDeleteClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);

        setConfirmOpen(true);
    };

    const handleMoveUpClick = async (event) => {
        event.stopPropagation();
        setMenuVisible(false);

        sort('up');
    };

    const handleMoveDownClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);

        sort('down');
    };

    const handleRenameClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);
        setRenameOpen(true);
    };

    const sort = async (direction) => {
        showProgress("Moving...");
        const currentIndex = models.findIndex(item => item.key === model.key);
        const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

        if (newIndex >= 0 && newIndex < models.length) {
            const targetItem = models[newIndex];
            const currentSort = model.sort;
            model.sort = targetItem.sort;
            targetItem.sort = currentSort;

            try {
                await updateDoc(doc(db, collections.models, model.key), { sort: model.sort });
                await updateDoc(doc(db, collections.models, targetItem.key), { sort: targetItem.sort });

                activity.log(selectedApp.key, 'writes', 2);
            } catch (error) {
                console.error('Error updating sort order:', error);
            }
        }

        hideProgress();
    };

    const rename = async () => {
        const data = {
            title: modelTitle
        };

        try {
            await updateDoc(doc(db, collections.models, model.key), data);

            // Update the title in the models state
            const updatedFormFields = models.map(item => 
                item.key === model.key ? { ...item, title: modelTitle } : item
            );
            setFormFields(updatedFormFields);

            activity.log(selectedApp.key, 'writes', 1);

            setRenameOpen(false);
        } catch (error) {
            console.error('Error renaming model:', error);
        }
    };

    const deleteModel = async () => {

        showProgress("Deleting menu item...");

        try {
            const modelKey = model.key;

            await modelManager.delete(selectedApp.key, modelKey);

            // Update local models state to delete the deleted model
            const updatedFields = models.filter(item => item.key !== modelKey);
            setFormFields(updatedFields);
        } catch (error) {
            console.error("Error deleting model: ", error);
        }

        hideProgress();
    };

    return (
        <div className="collection-item-menu-outer-container">

            {/* MENU BUTTON */}
            <div className="collection-item-menu-container">
                <ContextMenuButton 
                    title="Menu Options" 
                    icon={DotsVerticalIcon} 
                    iconSize={11}
                    onToggle={toggleMenu} 
                    dotsColor={isActive ? theme.highlightForegroundColor : theme.foregroundColorFaded}
                />
            </div>

            {/* MENU */}
            <ContextMenuPopup
                visible={menuVisible}
                setVisible={setMenuVisible}
                backgroundColor={theme.backgroundColorFaded}
                width={170}
                position={menuPosition}>

                {/* BASE OPTIONS */}
                <ContextMenuGroup title={model ? model.title : ""}>

                    <ContextMenuOption
                        icon={RenameIcon}
                        text="Rename"
                        onClick={handleRenameClick}
                    />

                    <ContextMenuOption
                        icon={UpIcon}
                        text="Move Up"
                        onClick={handleMoveUpClick}
                    />

                    <ContextMenuOption
                        icon={DownIcon}
                        text="Move Down"
                        onClick={handleMoveDownClick}
                    />

                    <ContextMenuOption
                        icon={DeleteIcon}
                        text="Delete"
                        onClick={handleDeleteClick}
                    />

                </ContextMenuGroup>

            </ContextMenuPopup>

            <Modal title="Rename Menu Item" isOpen={renameOpen} onClose={() => setRenameOpen(false)}>
                <input type="text"
                    className="modal-input"
                    value={modelTitle}
                    onChange={(e) => setModelTitle(e.target.value)}
                    style={{
                        backgroundColor: theme.backgroundColorFaded,
                        borderColor: theme.backgroundColorFaded,
                        color: theme.foregroundColor
                    }}
                />
                <div className="modal-buttons">
                    <button onClick={rename} className="modal-button"
                        style={{ color: theme.foregroundColor }}>
                        Save
                    </button>
                </div>
            </Modal>
            <Modal title="Delete Collection" width={400} isOpen={confirmOpen} onClose={() => setConfirmOpen(false)}>
                <div className="collection-item-menu-confirm-message"
                    style={{
                        color: theme.foregroundColor
                    }}
                    >
                    This will permanently delete all data currently stored in this collection. Continue?
                </div>
                <div className="modal-buttons">
                    <button onClick={deleteModel} className="modal-button"
                        style={{ color: theme.foregroundColor }}>
                        Delete
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default CollectionItemMenu;
