import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detections
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './Forum.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import AddButton from '../../addbutton/AddButton';
import ForumPost from './forumpost/ForumPost';
import ForumPosts from './forumposts/ForumPosts';
import ForumPostForm from './forumpostform/ForumPostForm';
import ForumToolBar from './ForumToolBar';

/**
 * Forum Component
 * 
 * This component renders a forum channel.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Forum = () => {
  const { theme } = useTheme();
  const {
    selectedForumPost
  } = useContext(Global);

  // State Variables
  const [formVisible, setFormVisible] = useState(false);

  useEffect(() => {
    console.log("Forum.js - isMobile: ", isMobile);
    console.log("Forum.js - selectedForumPost: ", selectedForumPost);
  }, [selectedForumPost, isMobile]);

  // Handle the click event for the add button
  const handleAddClick = async () => {
    setFormVisible(true);
  };

  return (
    <>
      {/* CONTAINER */}
      <div className={(isMobile || isTablet) ? "forum-container-mobile" : "forum-container"}>

        {(!isMobile || (isMobile && !selectedForumPost)) && (
          <>
            {/* LEFT */}
            <div className="forum-left">

              {/* TOOLBAR */}
              <ForumToolBar />

              {/* POSTS */}
              <ForumPosts />

              {/* ADD BUTTON */}
              <div className="forum-add-button">
                <AddButton onClick={handleAddClick} />
              </div>

            </div>

          </>
        )}

        {selectedForumPost &&
          <>
            {/* RIGHT */}
            <div className={isMobile ? "forum-right-mobile" : "forum-right"}
              style={{
                borderLeftColor: theme.backgroundColorFaded
              }}>
              <ForumPost />
            </div>
          </>
        }

      </div>

      {/* POST FORM */}
      <ForumPostForm
        isVisible={formVisible}
        setVisible={setFormVisible}
      />

    </>
  );
};

export default Forum;
