import React, { useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Global
import { Global } from '../../../../Global';

// Utilities
import { FormMode } from '../../../../common/utilities/Constants';

// Styles
import './UserList.css';
import { useTheme } from '../../../../ThemeContext';

// Components
import AppUserSummary from '../../../../common/appusers/AppUserSummary';
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';

// Images
import CheckOffIcon from '../../../../common/svg/CheckOffIcon';
import CheckOnIcon from '../../../../common/svg/CheckOnIcon';
import ForwardIcon from '../../../../common/svg/ForwardIcon';

// Managers
import AppUserManager from '../../../../common/managers/AppUserManager';

const appUserManager = new AppUserManager();

const UserList = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
    readOnly = false
}) => {
    const { theme } = useTheme();
    const {
        appUsers,
        formMode,
        selectedApp,
        setAppUsers,
        setAppUsersVisible,
        setSelectedAppUser,
        setSelectedObject,
        setSelectedModel,
    } = useContext(Global);

    // State variables
    const [relatedAppUsers, setRelatedAppUsers] = useState([]);
    const [tempRelatedAppUsers, setTempRelatedAppUsers] = useState([]); // Local state for ADD mode
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tempKey, setTempKey] = useState(null); // Temporary key for the new object
    const [checkedUsers, setCheckedUsers] = useState({}); // State to track checked users

    // Initialize checked state based on the object or temp state
    useEffect(() => {
        if (formMode === FormMode.ADD) {
            return;
        }
        const initializeCheckedState = () => {
            const initialCheckedUsers = {};
            appUsers.forEach(appUser => {
                const isChecked = object[field.key]?.includes(appUser.userKey) || tempRelatedAppUsers.some((obj) => obj.userKey === appUser.userKey);
                initialCheckedUsers[appUser.userKey] = isChecked;
            });
            setCheckedUsers(initialCheckedUsers); // Update local state
        };

        initializeCheckedState();
    }, [object, appUsers, tempRelatedAppUsers, field.key]);

    // Initialize state for ADD mode
    useEffect(() => {
        if (formMode === FormMode.ADD) {
            setRelatedAppUsers([]);
            setTempKey(uuidv4()); // Generate a temporary key
        }
    }, [formMode]);

    // Fetch app users and relationships when object changes
    useEffect(() => {
        const fetchAppUsers = async () => {
            try {
                const appUsers = await appUserManager.fetchAppUsers(selectedApp.key);
                setAppUsers(appUsers);
            } catch (error) {
                console.error('Failed to fetch related app users:', error);
            }
        };

        const fetchRelationships = async () => {
            try {
                if (object && object[field.key]) {
                    const userKeys = object[field.key]; // Array of user keys stored in the object field
                    
                    // Fetch app users that match the user keys
                    const relatedAppUsers = await appUserManager.fetchAppUsersWithKeys(selectedApp.key, userKeys);
                    setRelatedAppUsers(relatedAppUsers);
                }
            } catch (error) {
                console.error('Failed to fetch related objects:', error);
            }
        };

        fetchAppUsers();

        if (object && formMode !== FormMode.ADD) {
            fetchRelationships();
        }
    }, [object, selectedApp.key, field.key, formMode]);

    // Handle item selection check/uncheck
    const handleCheck = (e, appUser) => {
        e.stopPropagation();

        const isChecked = checkedUsers[appUser.userKey];
        const updatedCheckedUsers = {
            ...checkedUsers,
            [appUser.userKey]: !isChecked, // Toggle the check state
        };
        setCheckedUsers(updatedCheckedUsers); // Update the checked state

        if (isChecked) {
            // Uncheck logic here
            if (formMode === FormMode.ADD) {
                const updatedTempUsers = tempRelatedAppUsers.filter((obj) => obj.userKey !== appUser.userKey);
                setTempRelatedAppUsers(updatedTempUsers);
                onUpdate(field, updatedTempUsers.map((user) => user.userKey)); // Update parent
            } else {
                appUserManager.deleteRelationship(selectedApp.key, object.key, appUser.userKey, field.key);
                const updatedRelatedUsers = relatedAppUsers.filter((obj) => obj.userKey !== appUser.userKey);
                setRelatedAppUsers(updatedRelatedUsers);
                onUpdate(field, updatedRelatedUsers.map((user) => user.userKey)); // Update parent
            }
        } else {
            // Check logic here
            if (formMode === FormMode.ADD) {
                const updatedTempUsers = [...tempRelatedAppUsers, appUser];
                setTempRelatedAppUsers(updatedTempUsers);
                onUpdate(field, updatedTempUsers.map((user) => user.userKey)); // Update parent
            } else {
                appUserManager.createRelationship(selectedApp.key, object.key, appUser.userKey, field.key);
                const updatedRelatedUsers = [...relatedAppUsers, appUser];
                setRelatedAppUsers(updatedRelatedUsers);
                onUpdate(field, updatedRelatedUsers.map((user) => user.userKey)); // Update parent
            }
        }
    };

    // Handle item forward
    const handleForward = (appUser) => {
        setAppUsersVisible(true);
        setSelectedAppUser(appUser);
        setSelectedObject(null);
        setSelectedModel(null);
    };

    const handleSelectClick = () => {
        setIsModalOpen(true);
    };

    const handleOverlayClick = () => {
        setIsModalOpen(false);
    };

    return (
        <FieldContainer readOnly={readOnly} field={field}>
            <FieldHeader field={field} readOnly={readOnly} showFieldMenu={showFieldMenu} />
            <div className="user-list-items">
                {(formMode === FormMode.ADD ? tempRelatedAppUsers : relatedAppUsers).map((appUser, index) => (
                    <div key={appUser.userKey || index} className="user-list-item">
                        <div className="user-list-item-summary" onClick={() => handleForward(appUser)}>
                            <AppUserSummary appUser={appUser} />
                        </div>
                        <div className="user-list-item-forward" onClick={() => handleForward(appUser)}>
                            <ForwardIcon color={theme.foregroundColor} width="16" height="16" />
                        </div>
                    </div>
                ))}
            </div>

            {!readOnly && (
                <>
                    <div
                        className="user-list-summary-button"
                        style={{ color: theme.foregroundColorFaded }}
                        onClick={handleSelectClick}>
                        Select
                    </div>

                    {isModalOpen && (
                        <div className="user-list-modal-overlay" onClick={handleOverlayClick}>
                            <div className="user-list-modal-panel" style={{ backgroundColor: theme.backgroundColorFaded }} onClick={(e) => e.stopPropagation()}>
                                {appUsers.map((appUser, index) => (
                                    <div key={appUser.userKey || index} className="user-list-modal-summary-row">
                                        <div className="user-list-modal-summary-checkbox" onClick={(e) => handleCheck(e, appUser)}>
                                            {checkedUsers[appUser.userKey] ? (
                                                <CheckOnIcon color={theme.highlightBackgroundColor} checkColor={theme.foregroundColor} width="22" height="22" />
                                            ) : (
                                                <CheckOffIcon color={theme.highlightBackgroundColor} width="22" height="22" />
                                            )}
                                        </div>
                                        <div className="user-list-modal-summary-wrapper" onClick={(e) => handleCheck(e, appUser)}>
                                            <AppUserSummary appUser={appUser} />
                                        </div>
                                    </div>
                                ))}
                                <div className="user-list-done-button"
                                    style={{
                                        color: theme.highlightForegroundColor,
                                        backgroundColor: theme.highlightBackgroundColor
                                    }}
                                    onClick={handleOverlayClick}>
                                    Done
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </FieldContainer>
    );
};

export default UserList;
