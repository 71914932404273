import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Device Detections
import { isMobile } from 'react-device-detect';

// Utilities
import { FormMode } from '../../common/utilities/Constants';

// Styles
import './MenuItems.css';

// Theme
import { useStyle, useTheme } from '../../ThemeContext';

// Images
import CalendarIcon from '../../common/svg/CalendarIcon';
import HomeIcon from '../../common/svg/HomeIcon';
import MessagesIcon from '../../common/svg/MessagesIcon';
import SettingsIcon from '../../common/svg/SettingsIcon';
import UsersIcon from '../../common/svg/UsersIcon';

// Components
import Channels from './channels/Channels';
import Collections from './collections/Collections';
import MenuHeader from './MenuHeader';
import MenuItemMenu from './MenuItemMenu';
import MenuSettings from './MenuSettings';
import NavBar from '../../common/components/navbar/NavBar';

const InlineStyles = useStyle`
    .menu-item:hover {
        background-color: ${(props) => props.hoverColor} !important;
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

const MenuItems = () => {
    const { theme } = useTheme();
    const {
        currentUser,
        models,
        resetVisibility,
        selectedApp,
        selectedModel,
        setFormMode,
        setCollectionAddOpen,
        setSettingsVisible,
        settingsVisible,
        userRole,
    } = useContext(Global);

    const [isMenuSettingsOpen, setMenuSettingsOpen] = useState(false);

    const handleItemClick = (visibilitySetter) => {
        resetVisibility();
        // Show the add form on the desktop
        if (!isMobile) {
            setFormMode(FormMode.ADD);
        }
        visibilitySetter(true);
    };

    const handleAddCollectionClick = () => {
        setCollectionAddOpen(true);
    };

    const MenuItem = ({ menuItemKey, isActive, onClick, children, Icon = null }) => (
        <div
            className="menu-item"
            style={{
                backgroundColor: isActive ? theme.highlightBackgroundColor : 'transparent'
            }}>
            <div className="menu-item-left"
                onClick={onClick}>
                {Icon &&
                    <Icon
                        color={isActive ? theme.highlightForegroundColor : theme.foregroundColorFaded}
                        width="18"
                        height="14"
                    />
                }
                <div className="menu-item-text"
                    style={{
                        color: isActive ? theme.highlightForegroundColor : theme.foregroundColor
                    }}>
                    {children}
                </div>
            </div>

            {userRole === "ADMIN" &&
                menuItemKey &&
                <div
                    className="model-item-edit-image">
                    <MenuItemMenu model={models.find(model => model.key === menuItemKey)} isActive={isActive} />
                </div>
            }
        </div>
    );

    return (
        <>
            <InlineStyles
                hoverColor={theme.backgroundColorFaded}
            />
            <div className="menuitems-container"
                style={{
                    backgroundColor: theme.backgroundColor,
                }}>

                {isMobile &&
                    <MenuHeader />
                }

                <div className="menuitems-list">

                    {/* LIST *}
                    {/*
                    {isMobile &&
                        <>
                            <MenuItem
                                key="RUNHOME"
                                isActive={runHomeVisible}
                                onClick={() => handleItemClick(setRunHomeVisible)}
                                Icon={HomeIcon}>
                                Home
                            </MenuItem>

                            <MenuItem
                                key="MESSAGES"
                                isActive={messagesVisible}
                                onClick={() => handleItemClick(setMessagesVisible)}
                                Icon={MessagesIcon}>
                                Messages
                            </MenuItem>

                            <MenuItem
                                key="CALENDAR"
                                isActive={calendarVisible}
                                onClick={() => handleItemClick(setCalendarVisible)}
                                Icon={CalendarIcon}>
                                Calendar
                            </MenuItem>

                            <MenuItem
                                key="USERS"
                                isActive={false}
                                onClick={() => handleItemClick(setAppUsersVisible)}
                                Icon={UsersIcon}>
                                People
                            </MenuItem>
                        </>
                    }
                        */}

                    {/* CHANNELS */}
                    <Channels />

                    {/* COLLECTIONS */}
                    <Collections />

                    {/* SETTINGS */}
                    {isMobile && (
                        <MenuItem
                            key="SETTINGS"
                            isActive={settingsVisible}
                            onClick={() => handleItemClick(setSettingsVisible)}
                            Icon={SettingsIcon}>
                            Settings
                        </MenuItem>
                    )}

                    {selectedModel &&
                        <MenuSettings
                            modelKey={selectedModel.key}
                            isMenuSettingsOpen={isMenuSettingsOpen}
                            setMenuSettingsOpen={setMenuSettingsOpen}
                        />
                    }

                </div>

                {/* BUTTONS */}
                {isMobile &&
                    <div className="menu-items-buttons">

                        {/* NAV BAR */}
                        {selectedApp && currentUser &&
                            <NavBar />
                        }

                    </div>
                }
            </div>
        </>
    );
};

export default MenuItems;
