// Firebase
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Managers
import { activity } from './ActivityManager';
import RoleManager from './RoleManager';

const roleManager = new RoleManager();

class UserManager {

    /**
      * Fetches a user by its user key.
      * 
      * @param {string} userKey - Key of the user to retrieve.
      */
    async fetchUser(userKey) {
        try {
            // Create a reference to a specific user document using userKey
            const userRef = doc(db, collections.users, userKey);

            // Get the document
            const docSnap = await getDoc(userRef);

            if (docSnap.exists()) {
                // If the document exists, construct and return the user object
                return {
                    id: docSnap.id, // Include the document ID
                    ...docSnap.data() // Include all fields from the document
                };
            } else {
                // If no document exists for the provided key, handle accordingly
                console.log("No user found with the given userKey.");
                return null; // Or you could throw an error, depending on your use case
            }
        } catch (error) {
            console.error("Error fetching user:", error);
            throw error; // Rethrow the error to handle it appropriately
        }
    }

    /**
     * Determines if a user with the email already exists. Returns the user
     * if true, or null if no user with the email exists.
     * 
     * @param {string} email - User email to check for.
     * @returns {Promise<Object|null>} A promise that resolves to the user object if found, or null if not found.
    */
    async fetchUserWithEmail(email) {
        try {
            const usersCollection = collection(db, collections.users);
            const q = query(usersCollection, where("email", "==", email));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Assuming there's only one user with this email
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching user:", error);
            throw error;
        }
    }

    /**
     * Determines if a user with the phone number already exists. Returns the user
     * if true, or null if no user with the phone exists.
     * 
     * @param {string} phone - User phone to check for.
     * @returns {Promise<Object|null>} A promise that resolves to the user object if found, or null if not found.
    */
    async fetchUserWithPhone(phone) {
        try {
            const usersCollection = collection(db, collections.users);
            const q = query(usersCollection, where("phone", "==", phone));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Assuming there's only one user with this phone
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching user:", error);
            throw error;
        }
    }

    /**
     * Determines if a user with the username already exists. Returns the user
     * if true, or null if no user with the username exists.
     * 
     * @param {string} username - User username to check for.
     * @returns {Promise<Object|null>} A promise that resolves to the user object if found, or null if not found.
    */
    async fetchUserWithUsername(username) {
        try {
            const usersCollection = collection(db, collections.users);
            const q = query(usersCollection, where("username", "==", username));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Assuming there's only one user with this username
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching user:", error);
            throw error;
        }
    }

    /**
     * Determines if a user with the email already exists.
     * 
     * @param {string} email - User email to check for.
     * 
     * @returns {boolean} Whether the email exists.
    */
    async emailExists(email) {
        const user = await this.fetchUserWithEmail(email);
        return user !== null;
    }

    /**
     * Determines if a user with the username already exists.
     * 
     * @param {string} username - Username to check for.
     * 
     * @returns {boolean} Whether the username exists.
    */
    async usernameExists(username) {
        const user = await this.fetchUserWithUsername(username);
        return user !== null;
    }

    /**
     * Determines if a user with the handle already exists. Returns the user
     * if true, or null if no user with the handle exists.
     * 
     * @param {string} handle - Handle to check for.
     * @returns {Promise<Object|null>} A promise that resolves to the user object if found, or null if not found.
    */
    async fetchUserWithHandle(handle) {
        try {
            const usersCollection = collection(db, collections.users);
            const q = query(usersCollection, where("handle", "==", handle));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Assuming there's only one user with this email
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching user:", error);
            throw error;
        }
    }

    /**
     * Determines if a user with the handle already exists.
     * 
     * @param {string} handle - Handle to check for.
     * 
     * @returns {boolean} Whether the handle exists.
    */
    async handleExists(handle) {
        const user = await this.fetchUserWithHandle(handle);
        return user !== null;
    }

    async fetchCurrentUser(authUser) {
        if (!authUser) return null;

        try {
            const userData = await this.fetchUser(authUser.uid);
            if (userData) {
                return {
                    key: authUser.uid,
                    email: authUser.email,
                    displayName: authUser.displayName,
                    emailVerified: authUser.emailVerified,
                    phoneNumber: authUser.phoneNumber,
                    ...userData
                };
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error combining user data:", error);
            throw error;
        }
    }

    /**
     * Updates a user's information.
     * 
     * @param {string} userKey - Key of the object being updated.
     * @param {string} data - Data to update.
    */
    async update(userKey, data) {
        console.log(data);
        const userRef = doc(db, collections.users, userKey);
        try {
            await updateDoc(userRef, data);
        } catch (error) {
            console.error("Error updating current user: ", error);
            alert('Error updating current user.');
        }
    }

    /**
     * Determines whether the current user has permission for a specific action on a model within an app.
     *
     * Usage:
     * const hasPermission = await userManager.userHasPermission(currentUser, appKey, models, modelKey, permission);
     * if (hasPermission) {
     *     console.log("User has the permission to edit.");
     * } else {
     *     console.log("User does not have the permission to edit.");
     * }
     * 
     * @param {object} currentUser - The current user object.
     * @param {string} appKey - Key of the app to check permissions for.
     * @param {array} models - Array of models.
     * @param {string} modelKey - Key of the model to check.
     * @param {string} permission - The permission to check (MODELVIEW, ADD, EDIT, DELETE).
     * @returns {checkbox} True if the user has the permission, false otherwise.
     */
    async userHasPermission(currentUser, appKey, models, modelKey, permission) {
        if (!currentUser || !currentUser.roles) {
            console.error("Invalid user or user roles not available.");
            return false;
        }

        const roleKey = currentUser.roles[appKey];

        if (!roleKey) {
            //console.error("Role key not found for the given app.");
            return false;
        }

        return await roleManager.roleHasPermission(roleKey, models, modelKey, permission);
    }
}

export default UserManager;
