import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { doc, updateDoc } from 'firebase/firestore';
import { collections, db } from '../../../../firebaseConfig';

// Activity
import { activity } from '../../../managers/ActivityManager';

// Styles
import './ChannelItemMenu.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DotsVerticalIcon from '../../../svg/DotsVerticalIcon';

// Images
import DeleteIcon from '../../../svg/DeleteIcon';
import DownIcon from '../../../svg/DownIcon';
import RenameIcon from '../../../svg/RenameIcon';
import UpIcon from '../../../svg/UpIcon';

// Components
import ContextMenuButton from '../../../components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../../../components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../../components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../../components/contextmenu/ContextMenuPopup';

import ChannelDelete from '../channeldelete/ChannelDelete';
import ChannelEdit from '../channeledit/ChannelEdit';

/**
 * ChannelItemMenu Component
 * 
 * This component renders a menu for a channel menu item.
 * 
 * @param {object} channel - Channel providing the context.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ChannelItemMenu = ({ channel, isActive = false }) => {
    const { theme } = useTheme();
    const {
        channels,
        hideProgress,
        selectedApp,
        showProgress
    } = useContext(Global);

    // State Variables
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [menuVisible, setMenuVisible] = useState(false);
    const [renameOpen, setRenameOpen] = useState(false);

    /**
     * Method to toggle the display of the menu.
     * 
     * @param {string} e - Click event.
     */

    const toggleMenu = (e) => {
        if (!menuVisible) {
            e.preventDefault();
            setMenuPosition({ x: e.clientX, y: e.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    };

    /**
     * Method to handle a delete click event.
     * 
     * @param {string} e - Click event.
     */
    const handleDeleteClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);
        setDeleteOpen(true);
    };

    /**
     * Method to handle a move up click event.
     * 
     * @param {string} e - Click event.
     */
    const handleMoveUpClick = async (event) => {
        event.stopPropagation();
        setMenuVisible(false);
        sort('up');
    };

    /**
     * Method to handle a move down click event.
     * 
     * @param {string} e - Click event.
     */
    const handleMoveDownClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);
        sort('down');
    };

    /**
     * Method to handle a rename click event.
     * 
     * @param {string} e - Click event.
     */
    const handleRenameClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);
        setRenameOpen(true);
    };

    /**
     * Method to perform a sort operation.
     * 
     * @param {string} direction - Options: 'up' / 'down'
     */
    const sort = async (direction) => {
        showProgress("Moving...");
        const currentIndex = channels.findIndex(item => item.key === channel.key);
        const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

        if (newIndex >= 0 && newIndex < channels.length) {
            const targetItem = channels[newIndex];
            const currentSort = channel.sort;
            channel.sort = targetItem.sort;
            targetItem.sort = currentSort;

            try {
                await updateDoc(doc(db, collections.channels, channel.key), { sort: channel.sort });
                await updateDoc(doc(db, collections.channels, targetItem.key), { sort: targetItem.sort });

                activity.log(selectedApp.key, 'writes', 2);
            } catch (error) {
                console.error('Error updating sort order:', error);
            }
        }

        hideProgress();
    };

    return (
        <div className="channel-item-menu-outer-container">

            {/* MENU BUTTON */}
            <div className="channel-item-menu-container">
                <ContextMenuButton 
                    title="Menu Options" 
                    icon={DotsVerticalIcon} 
                    iconSize={11}
                    onToggle={toggleMenu} 
                    dotsColor={isActive ? theme.highlightForegroundColor : theme.foregroundColorFaded}
                />
            </div>

            {/* MENU */}
            <ContextMenuPopup
                visible={menuVisible}
                setVisible={setMenuVisible}
                backgroundColor={theme.backgroundColorFaded}
                width={170}
                position={menuPosition}>

                {/* BASE OPTIONS */}
                <ContextMenuGroup title={channel ? channel.title : ""}>

                    {/* EDIT */}
                    <ContextMenuOption
                        icon={RenameIcon}
                        text="Edit"
                        onClick={handleRenameClick}
                    />

                    {/* MOVE UP */}
                    <ContextMenuOption
                        icon={UpIcon}
                        text="Move Up"
                        onClick={handleMoveUpClick}
                    />

                    {/* MOVE DOWN */}
                    <ContextMenuOption
                        icon={DownIcon}
                        text="Move Down"
                        onClick={handleMoveDownClick}
                    />

                    {/* DELETE */}
                    <ContextMenuOption
                        icon={DeleteIcon}
                        text="Delete"
                        onClick={handleDeleteClick}
                    />

                </ContextMenuGroup>

            </ContextMenuPopup>

            {/* CHANNEL EDIT */}
            <ChannelEdit 
                channel={channel} 
                isOpen={renameOpen} 
                setOpen={setRenameOpen} 
            />

            {/* CHANNEL DELETE */}
            <ChannelDelete
                channel={channel} 
                isOpen={deleteOpen} 
                setOpen={setDeleteOpen} 
            />
        </div>
    );
};

export default ChannelItemMenu;
