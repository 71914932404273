import React, { useContext } from 'react';

// Global
import { Global } from '../Global';

// Device detection
import { isMobile } from 'react-device-detect';

// Styles
import './Home.css';

// Components
import AccountAbout from '../account/about/AccountAbout';
import AccountBilling from '../account/billing/AccountBilling';
import AccountMenu from '../account/menu/AccountMenu';
import Apps from './apps/Apps';
import LogoLarge from '../common/logolarge/LogoLarge';
import Prompt from '../home/Prompt';
import TitleBar from '../common/titlebar/TitleBar';
import VideoNetwork from '../VideoNetwork';

const Home = () => {
  const {
    accountAboutVisible,
    accountBillingVisible,
    accountVisible,
    studio,
  } = useContext(Global);

  return (
    <div className="home-container"
      style={{ backgroundColor: studio.backgroundColor }}>
      <div className="home-title-bar">
        <div className="home-title-bar-wrapper">
          <TitleBar />
        </div>
      </div>

      {/* MOBILE */}
      {isMobile ? (
        <>
          {accountBillingVisible ? (
            <AccountBilling />
          ) : (
            <>
              {accountAboutVisible ? (
                <AccountAbout />
              ) : (
                <>
                  {accountVisible ? (
                    <AccountMenu />
                  ) : (
                    <div className="home-scroller">
                      {/*<VideoNetwork />*/}
                      <div className="home-logo">
                        <LogoLarge />
                      </div>
                      <div className="home-prompt">
                        <Prompt />
                      </div>
                      <div className="home-apps">
                        <Apps />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {/* DESKTOP */}
          <div className="home-scroller">
            {/* <VideoNetwork /> */}
            <div className="home-logo">
              <LogoLarge />
            </div>
            <div className="home-prompt">
              <Prompt />
            </div>
            <div className="home-apps">
              <Apps />
            </div>
          </div>
        </>
      )}
    </div>
  );

};

export default Home;

