import React, { Suspense, startTransition } from 'react';

// Styles
import './AppAddItem.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import RightIcon from '../svg/RightIcon';

// Dynamic import of icons
const getIconComponent = (iconName) => {
    return React.lazy(() => import(`../svg/${iconName}`));
};

/**
 * AppAddItem Component
 * 
 * This component renders an app-type option when adding an app.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppAddItem = ({ icon, name, description, height, onClick }) => {
    const { theme } = useTheme();

    // Dynamically load the icon component based on the icon name
    const IconComponent = getIconComponent(icon);

    return (
        <>
            {/* CONTAINER */}
            <div className="app-add-item-container"
                style={{
                    borderBottomColor: theme.backgroundColorFaded,
                    borderTopColor: theme.backgroundColorFaded,
                    height: height ? height : 'auto'
                }}
                onClick={onClick}>
                <div className="app-add-item-top">
                    <div className="app-add-item-icon">

                        <Suspense fallback={<div>...</div>}>
                            <IconComponent
                                color={theme.highlightBackgroundColor}
                                width="18"
                                height="18"
                            />
                        </Suspense>

                    </div>
                    <div
                        className="app-add-item-label"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        {name}
                    </div>
                    <div className="app-add-item-arrow">
                        <RightIcon
                            color={theme.foregroundColor}
                            width="22"
                            height="22"
                        />
                    </div>
                </div>
                {description &&
                    <div className="app-add-item-bottom"
                        style={{
                            color: theme.foregroundColorFaded
                        }}>
                        {description}
                    </div>
                }
            </div>
        </>
    );
};

export default AppAddItem;
