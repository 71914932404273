import React from 'react';

// Styles
import './ChannelTypes.css';

// Images
import HashtagIcon from '../../../../common/svg/HashtagIcon';
import ForumIcon from '../../../../common/svg/ForumIcon';
import VideoIcon from '../../../../common/svg/VideoIcon';

// Components
import ChannelTypeItem from './ChannelTypeItem';

// Utilities
import { ChannelType } from '../../../../common/utilities/Constants';

/**
 * ChannelTypes Component
 * 
 * This component renders a container for channel types.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ChannelTypes = ({ selectedType, setSelectedType }) => {

    // Channel types
    const types = [
        {
            type: ChannelType.TEXT,
            icon: HashtagIcon,
            label: "Text"
        },
        {
            type: ChannelType.FORUM,
            icon: ForumIcon,
            label: "Forum"
        },
        {
            type: ChannelType.VIDEO,
            icon: VideoIcon,
            label: "Video Chat"
        }
    ];

    // Function to handle selection change
    const handleSelect = (type) => {
        setSelectedType(type); // Update the selected type
    };

    return (
        <div className="channel-types-container">
            {types.map((channel, index) => {
                const isFirst = index === 0;
                const isLast = index === types.length - 1;
                const isSelected = selectedType === channel.type;

                return (
                    <div key={channel.type}>
                        <ChannelTypeItem
                            icon={channel.icon}
                            label={channel.label}
                            isFirst={isFirst}
                            isLast={isLast}
                            selected={isSelected}
                            onClick={() => handleSelect(channel.type)} // Handle click to select
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default ChannelTypes;
