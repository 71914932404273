// Firebase
import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, setDoc, updateDoc, where, writeBatch } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

class AppManager {

    /**
     * Method to add a new app.
     * 
     * @param {string} key - The new key of the app.
     * @param {string} data - App data.
     * @returns {app} - New app.
     */
    async add(key, data) {

        await setDoc(doc(db, collections.apps, key), data);

        activity.log(key, 'writes', 1);

        return data;
    }

    /**
     * Fetches an app.
     * 
     * @param {string} key - App key.
     * @returns {Promise<Object|null>} A promise that resolves to the app object if found, or null if not found.
    */
    async fetchApp(key) {
        try {
            const appCollection = collection(db, collections.apps);
            const q = query(appCollection, where("key", "==", key));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching app:", error);
            throw error;
        }
    }

    /**
      * Fetches apps created by a specific user and subscribes to real-time updates.
      * 
      * @param {function} onUpdate - Callback function that handles the update.
      */
    fetchApps(onUpdate) {
        try {
            // Create a reference to the apps collection
            const appsCollection = collection(db, collections.apps);

            // Create a query to find apps by userKey and sort them by title
            const q = query(appsCollection, orderBy("title"));

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q, snapshot => {
                const appList = snapshot.docs.map(doc => ({
                    id: doc.id, // Include the document ID if needed
                    ...doc.data() // Spread operator to include all fields from the document
                }));

                // Call the onUpdate callback with the updated list
                if (onUpdate) onUpdate(appList);
            }, error => {
                console.error("Error fetching apps:", error);
            });

            // Return the unsubscribe function to allow the caller to unsubscribe later
            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error; // Rethrow the error to handle it in the calling function
        }
    }

    /**
     * Fetches apps that a user has access to.
     * 
     * @param {string} userKey - User key to get apps for.
     * @returns {Promise<Array>} A promise that resolves to an array of user apps.
     */
    async fetchUserApps(userKey) {
        try {
            // Step 1: Query the appusers collection to get appKey values for the given userKey
            const appUsersCollection = collection(db, collections.appusers);
            const appUsersQuery = query(appUsersCollection, where("userKey", "==", userKey));
            const appUsersSnapshot = await getDocs(appUsersQuery);

            // Extract appKey values from the query result
            const appKeys = appUsersSnapshot.docs.map(doc => doc.data().appKey);

            if (appKeys.length === 0) {
                return []; // Return an empty array if no apps are found for the user
            }

            // Step 2: Query the apps collection with the appKey values
            const appsCollection = collection(db, collections.apps);
            const appsQuery = query(appsCollection, where("__name__", "in", appKeys));
            const appsSnapshot = await getDocs(appsQuery);

            // Map the app details from the query result
            const appDetails = appsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            return appDetails;
        } catch (error) {
            console.error("Error fetching user apps:", error);
            throw error;
        }
    }

    /**
     * Updates an app's data in the Firestore database.
     * 
     * @param {string} appKey - The key (document ID) of the app to update.
     * @param {string} data - App-related data.
     * @returns {Promise<Object>} A promise that resolves to an object indicating the operation's success or failure.
     */
    async updateApp(appKey, data) {
        try {

            // Update the document in Firestore
            await updateDoc(doc(db, collections.apps, appKey), data);

            activity.log(appKey, 'writes', 1);

            // Return a success message
            return { success: true, message: "App updated successfully." };
        } catch (error) {
            console.error('Error updating app:', error);
            // Return an error message
            return { success: false, message: "Error updating app." };
        }
    }

    /**
     * Updates an app's version number in the Firestore database.
     * 
     * @param {string} appKey - The key (document ID) of the app to update.
     * @param {string} version - New version number of the app.
     * @returns {Promise<Object>} A promise that resolves to an object indicating the operation's success or failure.
     */
    async updateVersion(appKey, version) {
        await updateDoc(doc(db, collections.apps, appKey), { version });

        activity.log(appKey, 'writes', 1);
    }

    /**
     * Updates the app's rating data in the Firestore database.
     *
     * @param {string} appKey - The key (document ID) of the app to update.
     * @param {string} userKey - The key (document ID) of the user providing the rating.
     * @param {number} rating - The rating value provided by the user.
     * @returns {Promise<void>} A promise that resolves when the operation is complete.
     */
    async updateAppRatings(appKey, userKey, rating) {
        const appDocRef = doc(db, collections.apps, appKey);
        const appDoc = await getDoc(appDocRef);

        if (appDoc.exists()) {
            const appData = appDoc.data();
            const ratings = appData.ratings || {};

            // Add or update the user rating
            ratings[userKey] = rating;

            // Calculate the average rating
            const ratingValues = Object.values(ratings);
            const totalRatings = ratingValues.length;
            const averageRating = totalRatings > 0
                ? Math.round(ratingValues.reduce((sum, r) => sum + r, 0) / totalRatings)
                : 0;

            await updateDoc(appDocRef, { ratings, rating: averageRating });
        } else {
            // Handle the case where the app document does not exist
            console.error(`No app document found for appKey: ${appKey}`);
        }
    }

    /**
     * Deletes documents from a specified collection based on a query.
     * 
     * @param {object} batch - The Firestore batch operation.
     * @param {string} collectionPath - The path of the collection to delete documents from.
     * @param {object} queryConstraints - The constraints for querying the documents.
     * @param {string} appKey - The key of the app for logging activities.
     * @param {string} modelKey - (Optional) The key of the model for logging activities.
     */
    async deleteDocuments(batch, collectionPath, queryConstraints, appKey, modelKey = null) {
        const querySnapshot = await getDocs(query(collection(db, collectionPath), queryConstraints));

        activity.log(appKey, 'reads', querySnapshot.docs.length);

        querySnapshot.forEach(doc => {
            batch.delete(doc.ref);
            activity.log(appKey, 'deletes', 1);
        });
    };

    /**
     * Deletes an app from the Firestore database.
     * 
     * @param {string} appKey - The key (document ID) of the app to delete.
     */
    async deleteApp(appKey) {
        try {
            // Start a batch operation to execute multiple write operations as a single atomic unit
            const batch = writeBatch(db);

            // First, delete the app document from the "apps" collection
            const appRef = doc(db, collections.apps, appKey);
            batch.delete(appRef);

            // Query and delete all models associated with the selected app
            const modelsSnapshot = await getDocs(query(collection(db, collections.models), where("appKey", "==", appKey)));

            for (const modelDoc of modelsSnapshot.docs) {
                const originalModelData = modelDoc.data();
                const modelKey = originalModelData.key;
                const modelRef = modelDoc.ref;

                // Delete the model document itself
                batch.delete(modelRef);
                activity.log(appKey, 'deletes', 1);

                // Delete fields, objects, and summaries associated with the model
                await this.deleteDocuments(batch, collections.fields, where("modelKey", "==", modelKey), appKey, modelKey);
                await this.deleteDocuments(batch, modelKey, {}, appKey, modelKey);
            }

            // Delete menu items, app users, roles, and transactions related to the app's key
            await this.deleteDocuments(batch, collections.appusers, where("appKey", "==", appKey), appKey);
            await this.deleteDocuments(batch, collections.events, where("appKey", "==", appKey), appKey);
            await this.deleteDocuments(batch, collections.roles, where("appKey", "==", appKey), appKey);
            await this.deleteDocuments(batch, collections.transactions, where("appKey", "==", appKey), appKey);
            
            // Commit the batch
            await batch.commit();

        } catch (error) {
            console.error('Error deleting app:', error);
            return { success: false, message: "Error deleting app." };
        }
    }
}

export default AppManager;
