import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './RunHomeMenu.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import EditIcon from '../../common/svg/EditIcon';

// Components
import ContextMenuButton from '../../common/components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../../common/components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../common/components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../common/components/contextmenu/ContextMenuPopup';

const RunHomeMenu = () => {
    const { theme } = useTheme();
    const { 
        editingHeadlines,
        setEditingHeadlines 
    } = useContext(Global);

    // State Variables
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

    /**
     * Toggles visibility of the context menu.
     */
    const toggleMenu = (event) => {
        if (!menuVisible) {
            event.preventDefault();
            setMenuPosition({ x: event.clientX, y: event.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    };

    /**
     * Handles click on the toggle edit option.
     */
    const handleToggleEdit = async () => {
        setMenuVisible(false);
        setEditingHeadlines(!editingHeadlines);
    };

    return (
        <div className="form-menu-outer-container">

            {/* MENU BUTTON */}
            <div className="form-menu-container">
                <ContextMenuButton
                    title="Feed Options"
                    onToggle={toggleMenu}
                />
            </div>

            {/* MENU */}
            <ContextMenuPopup
                visible={menuVisible}
                setVisible={setMenuVisible}
                backgroundColor={theme.backgroundColorFaded}
                width={190}
                position={menuPosition}>

                {/* FORM OPTIONS */}
                <ContextMenuGroup title="Updates">

                    {/* TOGGLE EDIT */}
                    <ContextMenuOption
                        icon={EditIcon}
                        text={editingHeadlines ? "Cancel Editing" : "Manage Posts"}
                        onClick={handleToggleEdit}
                    />

                </ContextMenuGroup>

            </ContextMenuPopup>

        </div>
    );
};

export default RunHomeMenu;
