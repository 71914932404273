import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Styles
import './AccountAbout.css';

// Components
import AccountAboutPhoto from './AccountAboutPhoto';
import AccountAboutSaveButton from './AccountAboutSaveButton';
import AccountForm from '../components/accountform/AccountForm';

// Managers
import AppUserManager from '../../common/managers/AppUserManager';
import UserManager from '../../common/managers/UserManager';

const appUserManager = new AppUserManager();
const userManager = new UserManager();

const AccountAbout = () => {
    const {
        currentUser,
        selectedApp,
        setAppUsers,
        setCurrentUser
    } = useContext(Global);

    // State Variables
    const [username, setUsername] = useState(''); // Username (required)
    const [email, setEmail] = useState(''); // Username (required)
    const [fullName, setFullName] = useState(''); // Full Name (optionally required)
    const [displayName, setDisplayName] = useState(''); // Display Name (optionally required)
    const [handle, setHandle] = useState(''); // Handle (optionally required)

    // Validation States
    const [formValid, setFormValid] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);

    const handleSubmit = async () => {

        // Update the user's information in the database
        const data = {
            username: username,
            email: email
        }

        // Update the user's information in the database
        await userManager.update(currentUser.key, data);

        // Update the current user state
        setCurrentUser(prev => ({ ...prev, ...data }));

        // Update the user's information in the appusers collection if the app is selected
        if (selectedApp) {
            await appUserManager.updateUserInfo(selectedApp.key, currentUser.key, data );

            // Update the user's information in the search index
            //await userIndexManager.update(selectedApp.key, currentUser.key, newFirstName + " " + newLastName);

            // Update the global appUsers state array
            setAppUsers(prevAppUsers => prevAppUsers.map(appUser =>
                appUser.userKey === currentUser.key
                    ? { ...appUser, ...data }
                    : appUser
            ));

            // Log the activity
            activity.log(selectedApp.key, 'writes', 1);
        }
    };

    /**
     * Method to handle submit of the signup form.
     * 
     * @param {string} e - Click event.
     */
    const handleFormChange = async (
        username, email, fullName, displayName, handle, password, isValid
    ) => {
        setUsername(username);
        setEmail(email);
        setFullName(fullName);
        setDisplayName(displayName);
        setHandle(handle);
        setFormValid(isValid);
    };

    /**
     * Enable the submit button only if all required fields are valid.
     */
    useEffect(() => {
      setSubmitEnabled(formValid);
    }, [formValid]);

    return (

        <div className="account-about-container">

            <div className="account-about-top-section">
                <AccountAboutPhoto />
            </div>
            <div className="account-about-form">

                {/* FORM */}
                <AccountForm
                    onChange={handleFormChange}
                    user={currentUser}
                    requirePassword={false}
                    showLabels={false}
                />

                {/* SUBMIT */}
                <AccountAboutSaveButton onClick={handleSubmit} enabled={submitEnabled} />

            </div>

        </div>
    );
};

export default AccountAbout;
