import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './AppBar.css';

// Themes
import { useTheme } from '../../../ThemeContext';

// Components
import AppBarAdd from '../../../desktop/components/appbaradd/AppBarAdd';
import AppBarItem from '../../../desktop/components/appbaritem/AppBarItem';
import AppBarSearch from '../../../desktop/components/appbarsearch/AppBarSearch';

// Managers
import AppManager from '../../managers/AppManager';

const appManager = new AppManager();

const AppBar = () => {
    const { theme } = useTheme();
    const {
        apps,
        currentUser,
        setApps
    } = useContext(Global);

    useEffect(() => {

        if (!currentUser) return;

        async function fetchUserApps() {
            try {

                const results = await appManager.fetchUserApps(currentUser.key);
                setApps(results);

            } catch (error) {
                console.error('Failed to fetch user apps:', error);
            }
        }
        fetchUserApps();

    }, [currentUser, setApps]);

    return (
        <>
            {/* CONTAINER */}
            <div className="app-bar-container"
                style={{
                    backgroundColor: theme.backgroundColor,
                    borderRightColor: theme.backgroundColorFaded
                }}>

                {/* APPS */}
                {apps.map(app => (
                    <AppBarItem
                        key={app.key}
                        app={app}
                        count={parseInt(Math.random(0, 20) * 10)} // TEMP
                    />
                ))}

                {/* ADD BUTTON */}
                <AppBarAdd />

                {/* SEARCH BUTTON */}
                <AppBarSearch />

            </div>
        </>
    );
};

export default AppBar;
