import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { ref } from 'firebase/storage';
import { storage } from '../../../firebaseConfig';

// Styles
import './FieldRemove.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import Modal from '../../../common/components/modal/Modal';

// Managers
import FieldManager from '../../../common/managers/FieldManager';
import HeadlineManager from '../../../common/managers/HeadlineManager';
import StorageManager from '../../../common/managers/StorageManager';

const fieldManager = new FieldManager();
const headlineManager = new HeadlineManager();
const storageManager = new StorageManager();

const FieldRemove = ({
    field,
    confirmOpen,
    setConfirmOpen,
}) => {
    const { theme } = useTheme();
    const {
        formFields,
        hideProgress,
        selectedApp,
        selectedModel,
        setFormFields,
        showProgress,
        selectedObject
    } = useContext(Global);

    const deleteField = async () => {
        showProgress("Deleting field...");

        try {
            const fieldKey = field.key;

            await fieldManager.delete(selectedApp.key, fieldKey);

            // Update local fields state to delete the deleted field
            const updatedFields = formFields.filter(item => item.key !== fieldKey);
            setFormFields(updatedFields);

            // If the field is a gallery, delete the gallery data
            // Path: galleries/APPKEY/MODELKEY/FIELDKEY/
            if (field.type === 'gallery') {
                const storageRef = ref(storage, `galleries/${selectedApp.key}/${selectedModel.key}/${field.key}`);
                await storageManager.deleteFolderContents(storageRef);

                // Delete the headlines for any gallery in the object
                await headlineManager.deleteGalleryHeadlines(selectedObject.key)
            }

            // If the field is a videogallery, delete the videogallery data
            // Path: videogalleries/APPKEY/MODELKEY/FIELDKEY/
            if (field.type === 'videogallery') {
                const storageRef = ref(storage, `videogalleries/${selectedApp.key}/${selectedModel.key}/${field.key}`);
                await storageManager.deleteFolderContents(storageRef);

                // Delete the headlines for any gallery in the object
                await headlineManager.deleteGalleryHeadlines(selectedObject.key)
            }

            // If the field is a document archive, delete the archive data
            // Path: documents/APPKEY/MODELKEY/FIELDKEY/
            if (field.type === 'documents') {
                const storageRef = ref(storage, `documents/${selectedApp.key}/${selectedModel.key}/${field.key}`);
                await storageManager.deleteFolderContents(storageRef);

                // Delete the headlines for any gallery in the object
                await headlineManager.deleteDocumentHeadlines(selectedObject.key)
            }

        } catch (error) {
            console.error("Error deleting field: ", error);
        }

        hideProgress();
    };

    return (
        <>
            {selectedModel &&

                <Modal title="Remove Field" width={400} isOpen={confirmOpen} onClose={() => setConfirmOpen(false)}>

                    {/* CONFIRM MESSAGE */}
                    <div className="field-remove-confirm-message"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        This will remove this field for all {selectedModel.title}. Any data stored within the field will be deleted. Continue?
                    </div>

                    {/* CONFIRM BUTTON */}
                    <div className="modal-buttons">
                        <button onClick={deleteField} className="modal-button"
                            style={{ 
                                color: theme.foregroundColor, 
                                fontFamily: selectedApp.fontFamily
                            }}>
                            Remove
                        </button>
                    </div>

                </Modal>
                
            }

        </>

    );
};

export default FieldRemove;
