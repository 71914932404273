import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Charting
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

// Styles
import './AccountBilling.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AppSelector from '../../common/components/appselector/AppSelector';
import TimespanSelector from '../../common/components/timespanselector/TimespanSelector';

const AccountBilling = () => {
    const { theme } = useTheme();
    const {
        currentUser,
        selectedApp,
        setUserApps,
        studio,
        targetApp,
        timespan,
        userApps,
    } = useContext(Global);

    // Costs
    const [data, setData] = useState([]);
    const [totals, setTotals] = useState({ reads: 0, writes: 0, deletes: 0, uploads: 0 });
    const [totalCost, setTotalCost] = useState(0.0);

    // Function to return the label based on the targetApp value
    const getLabel = () => {
        if (targetApp === "ALL") return "All Apps";
        const app = userApps.find(app => app.key === targetApp);
        return app.title;
    };

    useEffect(() => {
        const fetchApps = async () => {
            if (!currentUser || !currentUser.key) return;

            // Fetch all apps for the current user
            const appsQuery = query(
                collection(db, collections.apps),
                where("userKey", "==", currentUser.key)
            );
            const appsSnapshot = await getDocs(appsQuery);
            const apps = appsSnapshot.docs.map(doc => ({ key: doc.id, ...doc.data() }));

            setUserApps(apps);

            const now = new Date();
            const timeBounds = {
                'CURRENT': new Date(), // Adjust this to your current billing period start date
                'LAST60': new Date(now.setDate(now.getDate() - 60)),
                'LAST24': new Date(now.setDate(now.getDate() - 1)),
                'LAST7': new Date(now.setDate(now.getDate() - 7)),
                'LAST30': new Date(now.setDate(now.getDate() - 30))
            };

            const startDate = timeBounds[timespan];

            // Filter apps if a specific app is selected
            const filtered = targetApp === "ALL" ? apps : apps.filter(app => app.key === targetApp);

            // Fetch activity data for the filtered apps
            const activityPromises = filtered.map(async (app) => {

                const q = query(
                    collection(db, collections.activity),
                    where("appKey", "==", app.key),
                    where("timestamp", ">=", startDate),
                    orderBy("timestamp", "asc")
                );

                const querySnapshot = await getDocs(q);
                return querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    timestamp: doc.data().timestamp.toDate().toLocaleDateString()
                }));
            });

            const activityData = (await Promise.all(activityPromises)).flat();

            // Group and aggregate data by day
            const aggregatedData = activityData.reduce((acc, item) => {
                const date = item.timestamp;
                if (!acc[date]) {
                    acc[date] = { timestamp: date, reads: 0, writes: 0, deletes: 0, uploads: 0 };
                }
                acc[date].reads += item.reads;
                acc[date].writes += item.writes;
                acc[date].deletes += item.deletes;
                acc[date].uploads += item.uploads;
                return acc;
            }, {});

            const aggregatedArray = Object.values(aggregatedData);
            setData(aggregatedArray);

            const totals = aggregatedArray.reduce((acc, item) => ({
                reads: acc.reads + item.reads,
                writes: acc.writes + item.writes,
                deletes: acc.deletes + item.deletes,
                uploads: acc.uploads + item.uploads,
            }), { reads: 0, writes: 0, deletes: 0, uploads: 0 });
            
            setTotals(totals);
            
            // Constants for cost calculation (our costs)
            const readCostPerOperation = 0.00000006;
            const writeCostPerOperation = 0.00000018;
            const deleteCostPerOperation = 0.00000002;
            const uploadOperationCostPer10k = 0.05 / 10000; // $0.05 per 10k operations
            const storageCostPerGB = 0.026; // $0.026 per GB
            const bytesPerGB = 1024 * 1024 * 1024; // Number of bytes in a GB
            
            // Calculate costs
            const readsCost = totals.reads * readCostPerOperation;
            const writesCost = totals.writes * writeCostPerOperation;
            const deletesCost = totals.deletes * deleteCostPerOperation;
            const uploadsOperationCost = totals.uploads / 10000 * uploadOperationCostPer10k;
            const storageCost = (totals.uploads / bytesPerGB) * storageCostPerGB;
            
            const totalCost = readsCost + writesCost + deletesCost + uploadsOperationCost + storageCost;
            
            setTotalCost(parseFloat(totalCost.toFixed(8)));            
        };

        fetchApps();
    }, [timespan, currentUser, targetApp, setUserApps]);

    /**
    * Formats a file size to a human-readable string.
    * @param {number} size - The file size in bytes.
    * @returns {string} - The formatted file size.
    */
    function formatFileSize(size) {
        const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
        let unitIndex = 0;

        while (size >= 1024 && unitIndex < units.length - 1) {
            size /= 1024;
            unitIndex++;
        }

        return `${Math.round(size)} ${units[unitIndex]}`;
    }

    function CustomTooltip({ payload, active }) {
        if (active && payload && payload.length > 0) {
            return (
                <div className="account-billing-chart-tooltip"
                    style={{
                        backgroundColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded
                    }}
                >
                    <p className="account-billing-chart-tooltip-label"
                        style={{
                            color: selectedApp ? theme.foregroundColor : studio.foregroundColor
                        }}>{`${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    }

    return (

        <div className="account-billing-container">

            {/* SELECTORS */}
            <div className="account-billing-selectors">
                <AppSelector />
                <TimespanSelector />
            </div>

            <div className="account-billing-chart">
                <LineChart width={400} height={300} data={data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" stroke={selectedApp ? selectedApp ? theme.backgroundColor : studio.backgroundColorFaded : selectedApp ? theme.backgroundColor : studio.backgroundColorFaded} />
                    <XAxis dataKey="timestamp" tick={{ fontSize: 10, fill: selectedApp ? theme.foregroundColor : studio.foregroundColor }} />
                    <YAxis tick={{ fontSize: 10, fill: selectedApp ? theme.foregroundColor : studio.foregroundColor }} />
                    <Tooltip content={<CustomTooltip />} />
                    <Line type="monotone" dataKey="reads" stroke={selectedApp ? theme.highlightBackgroundColor : studio.highlightBackgroundColor} activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="writes" stroke={selectedApp ? theme.foregroundColor : studio.foregroundColorFaded} />
                    <Line type="monotone" dataKey="deletes" stroke={selectedApp ? theme.backgroundColor : studio.backgroundColorFaded} />
                </LineChart>
            </div>

            {/* COSTS */}
            <table className="account-billing-totals-table"
                style={{
                    color: selectedApp ? theme.highlightBackgroundColor : studio.highlightBackgroundColor,
                    borderTopColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                    borderRightColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded
                }}>
                <tr>
                    <td className="account-billing-total-label"
                        style={{
                            color: selectedApp ? theme.foregroundColorFaded : studio.foregroundColorFaded,
                            borderLeftColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderBottomColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            fontFamily: selectedApp && selectedApp.fontFamily
                        }}>
                        Reads
                    </td>
                    <td className="account-billing-total-value"
                        style={{
                            color: selectedApp ? theme.foregroundColor : studio.foregroundColor,
                            borderLeftColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderBottomColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderRightColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            fontFamily: selectedApp && selectedApp.fontFamily
                        }}>
                        {totals.reads}
                    </td>
                </tr>
                <tr>
                    <td className="account-billing-total-label"
                        style={{
                            color: selectedApp ? theme.foregroundColorFaded : studio.foregroundColorFaded,
                            borderLeftColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderBottomColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            fontFamily: selectedApp && selectedApp.fontFamily
                        }}>
                        Writes
                    </td>
                    <td className="account-billing-total-value"
                        style={{
                            color: selectedApp ? theme.foregroundColor : studio.foregroundColor,
                            borderLeftColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderBottomColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderRightColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            fontFamily: selectedApp && selectedApp.fontFamily
                        }}>
                        {totals.writes}
                    </td>
                </tr>
                <tr>
                    <td className="account-billing-total-label"
                        style={{
                            color: selectedApp ? theme.foregroundColorFaded : studio.foregroundColorFaded,
                            borderLeftColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderBottomColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            fontFamily: selectedApp && selectedApp.fontFamily
                        }}>
                        Deletes
                    </td>
                    <td className="account-billing-total-value"
                        style={{
                            color: selectedApp ? theme.foregroundColor : studio.foregroundColor,
                            borderLeftColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderBottomColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderRightColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            fontFamily: selectedApp && selectedApp.fontFamily
                        }}>
                        {totals.deletes}
                    </td>
                </tr>
                <tr>
                    <td className="account-billing-total-label"
                        style={{
                            color: selectedApp ? theme.foregroundColorFaded : studio.foregroundColorFaded,
                            borderLeftColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderBottomColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            fontFamily: selectedApp && selectedApp.fontFamily
                        }}>
                        Uploads
                    </td>
                    <td className="account-billing-total-value"
                        style={{
                            color: selectedApp ? theme.foregroundColor : studio.foregroundColor,
                            borderLeftColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderBottomColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderRightColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            fontFamily: selectedApp && selectedApp.fontFamily
                        }}>
                        {formatFileSize(totals.uploads)}
                    </td>
                </tr>
                <tr>
                    <td className="account-billing-total-label"
                        style={{
                            color: selectedApp ? theme.foregroundColorFaded : studio.foregroundColorFaded,
                            borderLeftColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderBottomColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            fontFamily: selectedApp && selectedApp.fontFamily
                        }}>
                        Downloads
                    </td>
                    <td className="account-billing-total-value"
                        style={{
                            color: selectedApp ? theme.foregroundColor : studio.foregroundColor,
                            borderLeftColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderBottomColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderRightColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            fontFamily: selectedApp && selectedApp.fontFamily
                        }}>
                    </td>
                </tr>
                <tr>
                    <td className="account-billing-total-label"
                        style={{
                            color: selectedApp ? theme.foregroundColorFaded : studio.foregroundColorFaded,
                            borderLeftColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderBottomColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            fontFamily: selectedApp && selectedApp.fontFamily
                        }}>
                        AI Usage
                    </td>
                    <td className="account-billing-total-value"
                        style={{
                            color: selectedApp ? theme.foregroundColor : studio.foregroundColor,
                            borderLeftColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderBottomColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            borderRightColor: selectedApp ? theme.backgroundColor : studio.backgroundColorFaded,
                            fontFamily: selectedApp && selectedApp.fontFamily
                        }}>
                    </td>
                </tr>
                <tr>
                    <td className="account-billing-overall-label"
                        style={{
                            color: selectedApp ? theme.foregroundColor : studio.foregroundColor,
                            fontFamily: selectedApp && selectedApp.fontFamily
                        }}>
                        Total Cost
                        <div className="account-billing-overall-app"
                        style={{
                            color: selectedApp ? theme.foregroundColor : studio.foregroundColorFaded,
                            fontFamily: selectedApp && selectedApp.fontFamily
                        }}>
                            {getLabel()}
                        </div>
                    </td>
                    <td className="account-billing-overall-value"
                        style={{
                            color: selectedApp ? theme.foregroundColor : studio.foregroundColor,
                            fontFamily: selectedApp && selectedApp.fontFamily
                        }}>
                        ${totalCost.toFixed(2)}
                    </td>
                </tr>
            </table>
        </div>
    );
};

export default AccountBilling;
