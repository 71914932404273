import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import { collections, db, storage } from '../../firebaseConfig';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage';

// Styles
import './AppSettings.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AppSettingsDescription from './AppSettingsDescription';
import AppSettingsFont from './AppSettingsFont';
import AppSettingsIcon from './AppSettingsIcon';
import AppSettingsTheme from './AppSettingsTheme';
import AppSettingsTitle from './AppSettingsTitle';
import UserFields from './userfields/UserFields';
import Modal from '../../common/components/modal/Modal';

// Managers
import { activity } from '../../common/managers/ActivityManager';
import AppManager from '../../common/managers/AppManager';

// Themes
const defaultThemes = {
    dark: {
        backgroundColor: '#000000',
        backgroundColorFaded: '#222222',
        foregroundColor: '#ffffff',
        foregroundColorFaded: '#aaaaaa',
        highlightColor: '#ff4500'
    },
    light: {
        backgroundColor: '#ffffff',
        backgroundColorFaded: '#f0f0f0',
        foregroundColor: '#000000',
        foregroundColorFaded: '#555555',
        highlightColor: '#ff0000'
    }
};

const menu = [
    { key: 'GENERAL', title: 'General' },
    { key: 'USERFIELDS', title: 'User Fields' },
    { key: 'ICON', title: 'Icon' },
    { key: 'THEME', title: 'Colors' },
    { key: 'FONT', title: 'Font Family' },
];

/**
 * AppSettings Component
 * 
 * This component allows the user to edit the settings of an app.
 * 
 * @param {object} appKey - The key of the app to edit.
 * @param {string} appSettingsVisible - Whether the app settings modal is open.
 * @param {string} setAppSettingsVisible - Function to call to open or close the modal.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppSettings = ({ appKey, appSettingsVisible, setAppSettingsVisible }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        hideProgress,
        selectedApp,
        setPage,
        setSelectedApp,
        setStatusMessage,
        showProgress
    } = useContext(Global);

    // State Variables

    // Menu
    const [selectedOption, setSelectedOption] = useState('GENERAL');

    // Title
    const [title, setTitle] = useState('');

    // Description
    const [description, setDescription] = useState('');

    // Icon
    const [selectedDarkIcon, setSelectedDarkIcon] = useState(null);
    const [selectedLightIcon, setSelectedLightIcon] = useState(null);
    const [iconSize, setIconSize] = useState(1.0);

    // Themes
    const [themes, setThemes] = useState(defaultThemes);
    const [fontFamily, setFontFamily] = useState('');

    // Define state for required fields and displayKey
    const [requiredFields, setRequiredFields] = useState({
        fullname: false,
        displayname: false,
        handle: false,
        birthdate: false,
    });
    const [displayKey, setDisplayKey] = useState('username'); // Default to 'username'

    /** 
     * Updates the state when the selected app changes.
     */
    useEffect(() => {
        if (selectedApp) {
            setTitle(selectedApp.title || '');
            setDescription(selectedApp.description || '');
            setThemes(selectedApp.themes || defaultThemes);
            setIconSize(selectedApp.iconSize || 1.0);
            setFontFamily(selectedApp.fontFamily || 'Red Hat Display');
            setRequiredFields(selectedApp.requiredFields || {
                fullname: false,
                displayname: false,
                handle: false,
                birthdate: false,
            });
            setDisplayKey(selectedApp.displayKey || 'username');
        }
    }, [selectedApp]);

    /**
     * Method to handle an icon size change.
     * 
     * @param {decimal} size - Slider value, on a scale of 0 to 1.
     */
    const handleIconSizeChange = (size) => {
        setIconSize(size);
    };

    /**
     * Method to handle a theme update.
     * 
     * @param {object} updatedThemes - JSON object containing the updated themes.
     */
    const handleUpdateThemes = (updatedThemes) => {
        setThemes(updatedThemes);
    };

    /**
     * Method to handle a font family change.
     * 
     * @param {string} updatedFontFamily - Updated font family.
     */
    const handleFontChange = (updatedFontFamily) => {
        setFontFamily(updatedFontFamily);
    };

    /**
     * Method to handle saving of the app settings.
     */
    const handleSave = async () => {
        // Close the modal
        setAppSettingsVisible(false);

        // Validate the title
        if (!title.trim()) {
            alert('Please enter an app title.');
            return;
        }

        // Upload the dark icon if it was selected
        if (selectedDarkIcon) {
            await uploadIcon("DARK");
        }

        // Upload the light icon if it was selected
        if (selectedLightIcon) {
            await uploadIcon("LIGHT");
        }

        // Current timestamp
        const now = Timestamp.now();

        const data = {
            title: title,
            description: description,
            themes: themes,
            iconSize: iconSize,
            fontFamily: fontFamily,
            requiredFields: requiredFields,
            displayKey: displayKey,
            dateModified: now,
        };

        // Call updateApp to update the database
        const appManager = new AppManager();
        const result = await appManager.updateApp(appKey, data);

        if (result.success) {
            // Reset the model and close the modal
            setTitle('');
            setDescription('');
            setStatusMessage(result.message);

            // Update state
            setSelectedApp((prevApp) => ({
                ...prevApp,
                title: title,
                description: description,
                themes: themes,
                fontFamily: fontFamily,
                requiredFields: requiredFields,
                displayKey: displayKey,
            }));
        } else {
            // Display the error message
            setStatusMessage(result.message);
        }
    };

    /**
     * Method to upload an icon to Firebase Storage.
     * 
     * @param {string} mode - "DARK" or "LIGHT".
     */
    const uploadIcon = async (mode) => {
        const selectedFile = mode === 'DARK' ? selectedDarkIcon : selectedLightIcon;
        const fileExtension = selectedFile.name.split('.').pop();

        const createResizedImageBlob = (file) => {
            return new Promise((resolve, reject) => {
                const img = document.createElement('img');
                img.onload = () => {
                    const maxDimension = 1000;
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const ratio = Math.min(maxDimension / img.width, maxDimension / img.height);
                    const width = img.width * ratio;
                    const height = img.height * ratio;

                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.toBlob((blob) => {
                        if (blob) {
                            resolve(blob); // Resolve the promise with the blob
                        } else {
                            reject(new Error("Failed to create blob"));
                        }
                    }, `image/${fileExtension}`);
                };
                img.onerror = reject; // Handle any errors that occur when loading the image
                img.src = URL.createObjectURL(file); // Set the image source to the selected file
            });
        };

        try {
            const resizedBlob = await createResizedImageBlob(selectedFile); // Resize the image

            const logoFolderRef = ref(storage, `logos/${mode.toLowerCase()}/`);

            // List all files in the folder and delete existing ones
            const listResults = await listAll(logoFolderRef);
            const existingFiles = listResults.items.filter((item) =>
                item.name.startsWith(selectedApp.key)
            );
            for (const fileRef of existingFiles) {
                await deleteObject(fileRef);
            }

            // Upload the new file
            const storageRef = ref(storage, `logos/${mode.toLowerCase()}/${selectedApp.key}.${fileExtension}`);
            await uploadBytes(storageRef, resizedBlob); // Upload the resized blob
            const fileUrl = await getDownloadURL(storageRef); // Get the URL

            // Update the selected app object with the new logo
            const updatedApp = {
                ...selectedApp,
                [mode === 'DARK' ? 'logoDark' : 'logoLight']: fileUrl,
            };

            setSelectedApp(updatedApp); // Update app state

            // Update Firestore document
            await updateDoc(doc(db, collections.apps, selectedApp.key), updatedApp);

            activity.log(selectedApp.key, 'writes', 1);
        } catch (error) {
            console.error("Error handling app logo upload:", error);
        }
    };

    /**
     * Method to handle deleting an app.
     */
    const handleDelete = async () => {
        showProgress('Deleting app...');

        if (!selectedApp) {
            alert('No app selected!');
            hideProgress();
            return;
        }

        const message = selectedApp.title + " and all of its data will be permanently deleted. Are you sure you would like to continue?";

        if (!window.confirm(message)) {
            hideProgress();
            return;
        }

        try {
            setAppSettingsVisible(false);

            const appKey = selectedApp.key;
            const appManager = new AppManager();
            await appManager.deleteApp(appKey);

            setSelectedApp(null);
            setPage('HOME');
            setStatusMessage('App deleted successfully.');
        } catch (error) {
            console.error('Error deleting object and related data: ', error);
            setStatusMessage('Error deleting object and related data');
        }

        hideProgress();
    };

    /**
     * Method to handle a menu option click.
     * 
     * @param {string} key - The key of the selected option.
     */
    const handleOptionClick = (key) => {
        setSelectedOption(key);
    };

    /**
     * Method to handle a dark icon selection.
     * 
     * @param {file} file - The selected file.
     */
    const handleDarkIconSelect = (file) => {
        setSelectedDarkIcon(file);
    };

    /**
     * Method to handle a light icon selection.
     * 
     * @param {file} file - The selected file.
     */
    const handleLightIconSelect = (file) => {
        setSelectedLightIcon(file);
    };

    return (
        <>
            {/* MODAL */}
            <Modal
                title="App Settings"
                isOpen={appSettingsVisible}
                onClose={() => setAppSettingsVisible(false)}
                width="900px">

                {/* CONTAINER */}
                <div className="app-settings-container">
                    <div className="app-settings-wrapper">

                        {/* MENU */}
                        <div className="app-settings-menu">
                            {menu.map((option, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleOptionClick(option.key)}
                                    className="app-settings-menu-option"
                                    style={{
                                        backgroundColor: option.key === selectedOption
                                            ? theme.highlightBackgroundColor
                                            : theme.backgroundColor
                                    }}
                                >
                                    <div
                                        className="app-settings-menu-option-title"
                                        style={{
                                            color: selectedOption && selectedOption === option.key
                                                ? theme.highlightForegroundColor
                                                : theme.foregroundColor,
                                            fontFamily: selectedApp.fontFamily
                                        }}
                                    >
                                        {option.title}
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* DIVIDER */}
                        <div
                            className="app-settings-divider"
                            style={{
                                backgroundColor: theme.backgroundColorFaded
                            }}></div>

                        {/* CONTENT */}
                        <div className="app-settings-content">

                            {/* GENERAL */}
                            {selectedOption === 'GENERAL' && (
                                <>
                                    <AppSettingsTitle
                                        title={title}
                                        setTitle={setTitle}
                                    />
                                    <AppSettingsDescription
                                        description={description}
                                        setDescription={setDescription}
                                    />
                                </>
                            )}

                            {/* USER FIELDS */}
                            {selectedOption === 'USERFIELDS' && (
                                <UserFields
                                    requiredFields={requiredFields}
                                    setRequiredFields={setRequiredFields}
                                    displayKey={displayKey}
                                    setDisplayKey={setDisplayKey}
                                />
                            )}

                            {/* ICON */}
                            {selectedOption === 'ICON' && (
                                <AppSettingsIcon
                                    themes={themes}
                                    defaultSize={iconSize}
                                    onDarkSelect={handleDarkIconSelect}
                                    onLightSelect={handleLightIconSelect}
                                    onSizeChange={handleIconSizeChange}
                                />
                            )}

                            {/* THEME */}
                            {selectedOption === 'THEME' && (
                                <AppSettingsTheme themes={themes} onUpdate={handleUpdateThemes} />
                            )}

                            {/* FONT */}
                            {selectedOption === 'FONT' && (
                                <AppSettingsFont fontFamily={fontFamily} onChange={handleFontChange} />
                            )}
                        </div>
                    </div>

                    {/* SAVE BUTTON */}
                    <div className="app-settings-modal-buttons">
                        <button
                            type="button"
                            onClick={handleSave}
                            className="app-settings-modal-button"
                            style={{
                                color: theme.foregroundColor,
                                fontFamily: selectedApp.fontFamily || 'Open Sans'
                            }}>
                            Save Changes
                        </button>
                    </div>

                    {/* DELETE BUTTON */}
                    <div className="app-settings-modal-buttons">
                        <button
                            type="button"
                            onClick={handleDelete}
                            className="app-settings-modal-button-delete"
                            style={{
                                color: theme.foregroundColorFaded,
                                fontFamily: selectedApp.fontFamily || 'Open Sans'
                            }}>
                            Delete App
                        </button>
                    </div>
                    
                </div>
            </Modal>
        </>
    );
};

export default AppSettings;
