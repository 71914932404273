// Firebase
import { collection, onSnapshot, deleteDoc, doc, query, orderBy, setDoc, updateDoc, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Utilities
import { generateKey } from '../utilities/Keys';

// Managers
import { activity } from './ActivityManager';

class ForumPostManager {

    /**
     * Method to add a new forum post.
     * 
     * @param {string} appKey - App key.
     * @param {string} key - New post key.
     * @param {object} data - Forum post object.
     * 
     * @returns {post} - New forum post record.
    */
    async add(appKey, key, data) {

        await setDoc(doc(db, collections.forumposts, key), data);

        activity.log(appKey, 'writes', 1);

        return data;
    }

    /**
      * Fetches posts in a channel and subscribes to real-time updates.
      * 
      * @param {string} appKey - App key.
      * @param {string} channelKey - Channel key.
      * @param {function} onUpdate - Callback function that handles the update.
      */
    fetchAndSubscribe(appKey, channelKey, onUpdate) {

        try {
            const postsCollection = collection(db, collections.forumposts);

            const q = query(postsCollection,
                where("appKey", "==", appKey),
                where("channelKey", "==", channelKey),
                orderBy("dateCreated", "desc"));

            const unsubscribe = onSnapshot(q, snapshot => {
                const posts = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                if (onUpdate) {
                    onUpdate(posts);
                    activity.log(appKey, 'reads', posts.length);
                }
            }, error => {
                console.error("Error fetching posts:", error);
            });

            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error;
        }
    }

    /**
     * Deletes a post.
     * 
     * @param {string} key - Post key.
     */
    async delete(key) {
        try {

            await deleteDoc(doc(db, collections.forumposts, key));

        } catch (error) {
            console.error('Error deleting forum post:', error);
            return { success: false, message: "Error deleting post." };
        }
    }

    /**
     * Updates an post.
     * 
     * @param {string} appKey - App key.
     * @param {string} key - The document ID for the existing forum post.
     * @param {object} data - Post data.
     */
    async update(appKey, key, data) {

        // Update the event document in Firestore
        await updateDoc(doc(db, collections.forumposts, key), data);

        activity.log(appKey, 'writes', 1);

        // Return success message
        return data;
    }

}

export default ForumPostManager;
