import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { generateLove } from '../../common/utilities/Keys';

// Firebase
import { functions, httpsCallable } from '../../firebaseConfig';
import { Timestamp } from 'firebase/firestore';

// Styles
import './AppUserInviteModal.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import Modal from '../../common/components/modal/Modal';

// Managers
import InviteManager from '../../common/managers/InviteManager';

const inviteManager = new InviteManager();

const AppUserInviteModal = ({ open, setOpen }) => {
  const { theme } = useTheme();
  const {
    currentUser,
    hideProgress,
    roles,
    selectedApp,
    showProgress
  } = useContext(Global);

  const [email, setEmail] = useState("");
  const [newUserRoleKey, setNewUserRoleKey] = useState(roles.length > 0 ? roles[0].key : "ADMIN");

  const handleNewRoleChange = (e) => {
    setNewUserRoleKey(e.target.value);
  }

  const handleSend = async () => {

    // Validate the email
    if (email.trim() === "") return;

    showProgress("Sending invite...");
    setOpen(false);

    const appName = selectedApp.title;

    try {
      // Generate an invite key
      const key = generateLove();

      // Current timestamp
      const now = Timestamp.now();

      // Create an invite record
      const invite = {
        key: key,
        email: email,
        appKey: selectedApp.key,
        roleKey: newUserRoleKey,
        dateCreated: now
      };

      // Add the invite to the database
      await inviteManager.addInvite(key, invite);

      // Send the user an invite email, e.g. https://appdeck.ai/?i=key

      // TEMP - NAVIGATE TO INVITE LANDING
      //navigate('/?i=' + key);

      var inviteLink = 'https://appdeck.ai?i=' + key;
      var currentFirstName = currentUser.firstName;
      var currentLastName = currentUser.lastName;

      const sendInviteEmail = httpsCallable(functions, 'sendInviteEmail');

      try {
        const result = await sendInviteEmail({ email, appName, inviteLink, currentFirstName, currentLastName });
        if (result.data.success) {
          console.log('Invite email sent successfully');
          hideProgress();
        } else {
          console.error('Error sending invite email:', result.data.error);
        }
      } catch (error) {
        console.error('Error calling Firebase Function:', error);
      }
    } catch (error) {
      console.error("Error in processing user data:", error);
    }
  };

  return (
    <Modal
      title="Invite User"
      isOpen={open}
      onClose={() => setOpen(false)}
      width="300px">
      <div className="modal-label"
        style={{
          color: theme.foregroundColorFaded
        }}
      >
        Email Address
      </div>
      <input
        type="text"
        name="email"
        className="modal-input"
        style={{
          backgroundColor: theme.backgroundColorFaded,
          borderColor: theme.backgroundColorFaded,
          color: theme.foregroundColor
        }}
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <div className="modal-label"
        style={{ color: theme.foregroundColorFaded }}>
        Initial Role
      </div>
      <select
        className="modal-input"
        style={{
          backgroundColor: theme.backgroundColorFaded,
          borderColor: theme.backgroundColorFaded,
          color: theme.foregroundColor
        }}
        value={newUserRoleKey}
        onChange={handleNewRoleChange}>
        <option key="ADMIN" value="ADMIN">Administrator</option>
        {roles.map((role) => (
          <option key={role.key} value={role.key}>{role.title}</option>
        ))}
      </select>
      <div className="modal-buttons">
        <button
          type="button"
          className="modal-button"
          style={{
            color: theme.foregroundColor
          }}
          onClick={handleSend}>
          Send Invite
        </button>
      </div>
    </Modal >
  );
};

export default AppUserInviteModal;
